import React from "react"
import { ProjectAssetData } from "../../../models/project-assets"
import ComponentizedForm from "./component-valuation/ComponentizedForm/ComponentizedForm"

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
}

function ComponentValuationTab(props: IAssetInformationTabProps) {
  return (
   <React.Fragment>
        <ComponentizedForm assetData={props.assetData}></ComponentizedForm>
   </React.Fragment>
  )
}

export default ComponentValuationTab