import React, {useContext, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import './InsuranceForm.scss'
import {ProjectAssetData} from '../../../../../models/project-assets'
import {CurrencyFormatter} from '../../../../../../../shared/service/utils'
import {updateAssetInsuranceValuation} from '../../../../../api/asset-component-valuation-api'
import {ProjectContext} from '../../../../../context/ProjectContext'
import {ASSET_VALUATION_FORM_DEFAULT} from '../../../../../constant/asset-valuation-default'
import {IAssetInsuranceValuation} from '../../../../../models/project-asset/insurance-valuation'
import InsuranceRecord from './InsuranceRecord'
import {AccessControlProvider} from '../../../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../../../roles/models/role-model'

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
}

function InsuranceForm(props: IAssetInformationTabProps) {
  const {assetData} = props
  const {showToastrNotification, fetchAssetClassComponentOptions, assetClassComponentOptions} =
    useContext(ProjectContext)
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: {errors},
    watch,
    setValue,
  } = useForm({
    defaultValues: ASSET_VALUATION_FORM_DEFAULT,
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (assetClassComponentOptions.length === 0)
      fetchAssetClassComponentOptions(assetData.assetClass)

    reset({
      ...{
        id: assetData.id,
        fairValueTotal: 0,
      },
    })
  }, [assetData])

  const onSubmit = (values: IAssetInsuranceValuation) => {
    updateAssetInsuranceValuation(values)
      .then((result) => {
        showToastrNotification('Component Valuation Saved succesfully', 'success')
      })
      .catch((error) => {
        showToastrNotification('Error Saving Component Valuation. Please try again.', 'error')
      })
  }

  const computeFairValueTotal = () => {
    const componentList = getValues('componentized')
    if (componentList && componentList.length > 0) {
      return componentList.reduce((sum, currentItem) => {
        return sum + (currentItem.replacementCost || 0)
      }, 0)
    } else {
      return 0
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex justify-content-between componetize-hdr'>
          <div className='content-fvt'>
            <h2>Fair Value Total</h2>
            <h5 className='fvt-total'>{CurrencyFormatter(computeFairValueTotal())}</h5>
          </div>
          <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}>
            <button type='submit' className='btn btn-primary submit-record'>
              Save Changes
            </button>
          </AccessControlProvider>
        </div>
        <InsuranceRecord
          register={register}
          projectAssetId={assetData?.id}
          control={control}
          errors={errors}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          stateCode={assetData?.stateCode}
        />
      </form>
    </React.Fragment>
  )
}

export default InsuranceForm
