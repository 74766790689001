import {GridCellProps} from '@progress/kendo-react-grid'
import {Fragment} from 'react'

export const BadgeProjectAssetStatus = (props: GridCellProps) => {
  const {dataItem} = props
  const field = props.field || ''
  const dataValue = dataItem ? (dataItem[field] ? dataItem[field] : '') : ''
  const statuses = dataValue.split(',')

  return (
    <td>
      {statuses.map((item: any, index: any) => (
        <Fragment key={index}>
          <span key={index} className={'badge bg-primary mt-1'}>
            {item.trim()}
          </span>{' '}
        </Fragment>
      ))}
    </td>
  )
}
