import axios from 'axios'
import {
  BuildingElement,
  ConstructionDifficulty,
  FieldValue,
  IProjectAssetNote,
} from '../models/project-assets'
import {BuildingReplacementRate} from '../models/project-assets'
import {ProjectAssetData} from '../models/project-assets'
import {FileViewer, GalleryViewer} from '../models/file-attachment'
import {ISingleLineValutaion} from '../models/single-line-valuation'
import {ISingleLineInsuranceValuation} from '../models/single-line-insurance-valuation'
import {IMarketApproachValuation} from '../models/market-approach-valuation'

export const PROJECT_ASSETS = `${process.env.REACT_APP_API_URL}/ProjectAsset`

export const deleteProjectAsset = async (assetId: number) => {
  return await axios.delete(`${PROJECT_ASSETS}?id=${assetId}`)
}

export const duplicateProjectAsset = async (assetId: number) => {
  return await axios.post(`${PROJECT_ASSETS}/duplicateprojectasset`, {
    id: assetId,
  })
}

export const createProjectAsset = async (payload: ProjectAssetData) => {
  const response = await axios.post(`${PROJECT_ASSETS}`, payload)


  if (response !== null) {
    const formData = new FormData()

    if (payload.images.length > 0 || payload.attachments.length > 0) {
      formData.append('ProjectAssetId', response.data.toString())
      if (payload.images.length > 0) formData.append('ImageFile', payload.images[0].file)

      payload.attachments.forEach((attachment) => {
        formData.append('attachments', attachment.file)
      })

      await axios.post(`${PROJECT_ASSETS}/uploadImage`, formData)
    }
  }
  return response
}

export const updateProjectAsset = async (payload: ProjectAssetData) => {
  const response = await axios.put(`${PROJECT_ASSETS}`, payload)

  return response
}

export const updateProjectAssetCustomFields = async (payload: ProjectAssetData) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/updateassetcustomfields`, payload)

  return response
}

export const updateProjectAssetInspectionDetails = async (payload: ProjectAssetData) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/updateinspectiondetails`, payload)

  return response
}

export const updateProjectAssetConstructionDifficulty = async (
  payload: ConstructionDifficulty,
  projectAssetId: number
) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/updateconstructiondifficulty`, {
    ...payload,
    id: projectAssetId,
  })

  return response
}

export const bulkUpdateProjectAsset = async (
  projectId: string,
  assetId: number[],
  fieldValue: FieldValue
) => {
  try {
    const response = await axios.put(`${PROJECT_ASSETS}/updateprojectassetbulk`, {
      ids: assetId,
      fieldValue,
      projectId,
    })
    return [response, null]
  } catch (err: any) {
    return [null, err]
  }
}

export const getConstructionDifficulty = async () => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/ConstructionDifficulty`)
}

export const getAssetClassOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_ASSETS}/assetClassOptions?keyword=${keyword}`)
}

export const getAssetClassComponentOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_ASSETS}/assetClassComponentOptions?assetClass=${keyword}`)
}

export const getAssetClassComponentTypeOptions = async (assetClassComponent: string) => {
  return await axios.get(
    `${PROJECT_ASSETS}/assetClassComponentTypeOptions?assetClassComponent=${assetClassComponent}`
  )
}

export const getAsbestosStatusOptions = async (keyword: string) => {
  return await axios.get(`${PROJECT_ASSETS}/asbestosStatusOptions?keyword=${keyword}`)
}

export const getValuerOptions = async (projectId: string) => {
  return await axios.get(`${PROJECT_ASSETS}/valuerOptions?projectId=${projectId}`)
}

export const updateAssetAttachment = async (payload: FileViewer[], projectAssetId: number) => {
  try {
    const formData = new FormData()
    formData.append('id', projectAssetId.toString())
    payload.forEach((attachment, i) => {
      formData.append(`attachments[${i}].file`, attachment.file)
      formData.append(`attachments[${i}].fileExtension`, attachment.fileExtension?.toString() || '')
      formData.append(`attachments[${i}].fileKey`, attachment.fileKey?.toString() || '')
      formData.append(`attachments[${i}].fileName`, attachment.fileName?.toString() || '')
      formData.append(`attachments[${i}].id`, attachment.id?.toString() || '')
      formData.append(`attachments[${i}].length`, attachment.length?.toString() || '')
    })

    const response = await axios.post(`${PROJECT_ASSETS}/updateprojectassetattachments`, formData)
    return [response, null]
  } catch (err) {
    return [null, err]
  }
}

export const updateBuildingElement = async (assetId: number, buildingElement: BuildingElement) => {
  return await axios.patch(`${PROJECT_ASSETS}/updatebuildingelement`, {
    projectAssetId: assetId,
    ...buildingElement,
  })
}

export const updateProjectAssetInfo = async (payload: ProjectAssetData) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/updateassetinfomation`, payload)
  return response
}

export const updateBuildingReplacementRate = async (
  payload: BuildingReplacementRate,
  projectAssetId: number
) => {
  return await axios.patch(`${PROJECT_ASSETS}/updatebuildingreplacementrate`, {
    ...payload,
    id: projectAssetId,
  })
}

export const downloadAttachment = async (attachmentId: number, assetId: number) => {
  return await axios.post(`${PROJECT_ASSETS}/downloadattachment`, {
    id: attachmentId,
    assetId: assetId,
  })
}

export const saveProjectAssetNote = async (payload: IProjectAssetNote) => {
  if (payload.id === 0) {
    const response = await axios.post(`${PROJECT_ASSETS}/createprojectassetnote`, payload)
    return response
  } else {
    const response = await axios.put(`${PROJECT_ASSETS}/updateprojectassetnote`, payload)
    return response
  }
}

export const createProjectAssetNote = async (payload: IProjectAssetNote) => {
  const response = await axios.post(`${PROJECT_ASSETS}/createprojectassetnote`, payload)

  return response
}

export const updateProjectAssetNote = async (payload: IProjectAssetNote) => {
  const response = await axios.put(`${PROJECT_ASSETS}/updateprojectassetnote`, payload)

  return response
}

export const deleteProjectAssetNote = async (assetNoteId: number) => {
  return await axios.delete(`${PROJECT_ASSETS}/deleteprojectassetnote?id=${assetNoteId}`)
}

export const updateAssetGalleries = async (payload: GalleryViewer[], projectAssetId: number) => {
  try {
    const formData = new FormData()
    formData.append('projectAssetId', projectAssetId.toString())
    payload.forEach((attachment, i) => {
      if (attachment.file) {
        formData.append(`galleries[${i}].galleryFile`, attachment.file)
      }
      formData.append(`galleries[${i}].id`, attachment.id?.toString() || '')
    })

    const response = await axios.patch(`${PROJECT_ASSETS}/addremoveprojectassetgalleries`, formData)
    return [response, null]
  } catch (err) {
    return [null, err]
  }
}

export const getSingleLineValuationByProjectAssetId = async (assetId: number) => {
  return await axios.get(
    `${PROJECT_ASSETS}/getsinglelinevaluationbyprojectassetid?projectAssetId=${assetId}`
  )
}

export const saveSingleLineValuation = async (payload: ISingleLineValutaion) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/savesinglelinevaluation`, payload)

  return response
}

export const getSingleLineInsuranceValuationByProjectAssetId = async (assetId: number) => {
  return await axios.get(
    `${PROJECT_ASSETS}/getsinglelineinsurancevaluationbyprojectassetid?projectAssetId=${assetId}`
  )
}

export const saveSingleLineInsuranceValuation = async (payload: ISingleLineInsuranceValuation) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/savesinglelineinsurancevaluation`, payload)

  return response
}

export const updateProjectAssetLocation = async (payload: ProjectAssetData) => {
  const response = await axios.put(`${PROJECT_ASSETS}/updateprojectassetlocation`, payload)

  return response
}

export const getMarketApproachValuationByProjectAssetId = async (assetId: number) => {
  return await axios.get(
    `${PROJECT_ASSETS}/getmarketapporachvaluationbyprojectassetid?projectAssetId=${assetId}`
  )
}

export const saveMarketApproachValuation = async (payload: IMarketApproachValuation) => {
  const response = await axios.patch(`${PROJECT_ASSETS}/savemarketapproachvaluation`, payload)

  return response
}

export const getAdjustmentMatrixOptions = async () => {
  return await axios.get(`${PROJECT_ASSETS}/adjustmentMatrixOptions`)
}
