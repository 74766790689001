import {yupResolver} from '@hookform/resolvers/yup'
import React, {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useEffectOnce} from 'react-use'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {
  getSingleLineInsuranceValuationByProjectAssetId,
  saveSingleLineInsuranceValuation,
} from '../../../api/asset-api'
import {SINGLELINE_INSURANCE_VALUATION_FORM_DEFAULT} from '../../../constant/single-line-insurance-valuation-default'
import {ProjectContext} from '../../../context/ProjectContext'
import {ILeadUpTimes} from '../../../models/leadUpTimes'
import {ProjectAssetData} from '../../../models/project-assets'
import {IRebuildTimes} from '../../../models/rebuildTimes'
import {ISingleLineInsuranceValuation} from '../../../models/single-line-insurance-valuation'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'
import {singleLineInsuranceValuationValidationSchema} from '../../../validators/asset-form'
import SingleLineInsuranceValuationSection from '../../common/SingleLineInsuranceValuationSection/SingleLineInsuranceValuationSection'
import {RowWrapper} from './SingleLineValuationTab'
import {AccessControlProvider} from '../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type ISingleLineInsuranceValuationTabProps = {
  assetData: ProjectAssetData
}

function SingleLineInsuranceValuationTab(props: ISingleLineInsuranceValuationTabProps) {
  const {assetData} = props
  const {showToastrNotification, singleLineReplacementCost, leadUpTimes, rebuildTimes} =
    useContext(ProjectContext)
  const [singleLineInsuranceValuationData, setSingleLineInsuranceValuationData] =
    useState<ISingleLineInsuranceValuation>({
      ...SINGLELINE_INSURANCE_VALUATION_FORM_DEFAULT,
      projectAssetId: assetData.id,
    })
  const [singleLineReplacementCostFormat, setSingleLineReplacementCostFormat] = useState<string>('')

  useEffect(() => {
    setSingleLineReplacementCostFormat(formatNumberWithCommas(singleLineReplacementCost))
  }, [singleLineReplacementCost])

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid},
    clearErrors,
    setValue,
    getValues,
    watch,
    //trigger,
    reset,
  } = useForm({
    defaultValues: singleLineInsuranceValuationData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(singleLineInsuranceValuationValidationSchema) as any,
  })

  const renderFormComponent = (
    Component: React.FC<any>,
    additionalProps: Record<string, ProjectAssetData> = {}
  ) => (
    <Component
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      isValid={isValid}
      clearErrors={clearErrors}
      getValues={getValues}
      setValue={setValue}
      watch={watch}
      selectedData={singleLineInsuranceValuationData}
      setSelectedData={setSingleLineInsuranceValuationData}
      singleLineReplacementCost={singleLineReplacementCost}
    />
  )

  useEffectOnce(() => {
    getSingleLineInsuranceValuationByProjectAssetId(assetData.id).then((result: any) => {
      if (result.data.id === 0) {
        const defaultLeadUpPeriod = leadUpTimes.find(
          (obj: ILeadUpTimes) => obj.rebuildProjectCostGreaterThan > singleLineReplacementCost
        )
        const defaultRebuildPeriod = rebuildTimes.find(
          (obj: IRebuildTimes) => obj.rebuildProjectCostGreaterThan > singleLineReplacementCost
        )

        if (defaultLeadUpPeriod) {
          result.data.policyPeriod = defaultLeadUpPeriod.standardLeadUpTimeMonths
          result.data.leadUpPeriod = defaultLeadUpPeriod.standardLeadUpTimeMonths
        }
        if (defaultRebuildPeriod)
          result.data.rebuildPeriod = defaultRebuildPeriod.normalConstructionPeriodMonths
      }
      setSingleLineInsuranceValuationData(result.data)
      reset(result.data)
    })
  })

  const onSubmit = (values: ISingleLineInsuranceValuation) => {
    saveSingleLineInsuranceValuation(values).then((response: any) => {
      if (response) {
        setSingleLineInsuranceValuationData(values)
        reset({...values})
        showToastrNotification('Single Line Insurance Valuation Saved succesfully', 'success')
      } else {
        showToastrNotification(
          'Error Saving Single Line Insurance Valuation. Please try again.',
          'error'
        )
      }
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex justify-content-between'>
          <div className={`card bg-dark`}>
            <div className='card-body' style={{width: '350px'}}>
              <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>Replacement Cost</div>
              <div
                className={`fw-bolder text-inverse-dark fs-1 text-right`}
                style={{textAlign: 'right'}}
              >
                {singleLineReplacementCostFormat}
              </div>
            </div>
          </div>
          <div className='d-flex align-items-start'>
            <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}>
              <Button text={'Save Changes'} buttonType='primary' />
            </AccessControlProvider>
          </div>
        </div>

        <div className='col mt-4'>
          <RowWrapper>
            <div className='col'>{renderFormComponent(SingleLineInsuranceValuationSection)}</div>
          </RowWrapper>
        </div>
      </form>
    </>
  )
}

export default SingleLineInsuranceValuationTab
