import {useContext, useEffect, useState} from 'react'
import {Controller, useWatch, useForm} from 'react-hook-form'
import Select from 'react-select'
import {BuildingReplacementRate, ProjectAssetData} from '../../../models/project-assets'
import {
  transformDefaultNumericOptions,
  transformDefaultOptions,
} from '../../../transformers/project-transformer'
import {getDistinctObjects} from '../../../../../shared/service/utils'
import {IRegionIndex} from '../../../models/region-index'
import {IOption, IOptionNumber} from '../../../models/project-model'
import SectionTitleHeader from '../SectionTitleHeader'
import {updateBuildingReplacementRate} from '../../../api/asset-api'
import {ProjectContext} from '../../../context/ProjectContext'
import {buildingReplacementRateSchema} from '../../../validators/building-replacement-rate-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IBuildingReplacementRateProps = {
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
  valuationRegionOptions: IOption[]
  regionIndexes: IRegionIndex[]
}

const mainAreaOptions = [
  {value: 'Square Meters (sqm)', label: 'Square Meters (sqm)'},
  {value: 'Square Feet', label: 'Square Feet'},
  {value: 'Cubic Meters', label: 'Cubic Meters'},
]

const secondaryAreaOptions = [
  {value: 'Square Meters (sqm)', label: 'Square Meters (sqm)'},
  {value: 'Square Feet', label: 'Square Feet'},
  {value: 'Cubic Meters', label: 'Cubic Meters'},
]

function BuildingReplacementRates(props: IBuildingReplacementRateProps) {
  const {valuationRegionOptions, regionIndexes, selectedData, setSelectedData} = props
  const [locationOptions, setLocationOptions] = useState<IOption[]>([])
  const [regionIndexOptions, setRegionIndexOptions] = useState<IOptionNumber[]>([])
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const {showToastrNotification} = useContext(ProjectContext)

  const {
    register,
    control,
    formState: {errors},
    clearErrors,
    setValue,
    reset,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(buildingReplacementRateSchema) as any,
  })

  useEffect(() => {
    reset({...selectedData})
  }, [selectedData, reset])

  const stateCodeValue = useWatch({
    control,
    name: 'stateCode',
  })

  const locationBaseValue = useWatch({
    control,
    name: 'locationBase',
  })

  useEffect(() => {
    if (stateCodeValue && stateCodeValue !== '') {
      let locBase = regionIndexes.filter((item) => item.stateCode === stateCodeValue)
      let locBaseOption = transformDefaultOptions(locBase.map((res) => res.localGovernmentArea))
      let distinct: IOption[] = getDistinctObjects(locBaseOption, ['label', 'value'])
      setLocationOptions(distinct)

      setRegionIndexOptions([])
    }
  }, [stateCodeValue, regionIndexes])

  useEffect(() => {
    if (locationBaseValue && locationBaseValue !== '') {
      // setValue('locationPremiumAdjustment', 0)
      let regionIndex = regionIndexes.filter(
        (item) =>
          item.stateCode === stateCodeValue && item.localGovernmentArea === locationBaseValue
      )
      let regionIndexOption = transformDefaultNumericOptions(
        regionIndex.map((res) => res.regionalIndex)
      )
      let distinct: IOptionNumber[] = getDistinctObjects(
        regionIndexOption.map((regIndex) => ({
          value: +regIndex.value,
          label: +regIndex.label,
        })),
        ['label', 'value']
      )
      setRegionIndexOptions(distinct)
    }
  }, [locationBaseValue])

  /** */
  function formatNumberInput(input: any) {
    let cleanedInput = input.replace(/[^0-9.]/g, '')
    cleanedInput = cleanedInput.replace(/(\..*)\./g, '$1')
    cleanedInput = cleanedInput.substring(0, 10)
    const dotIndex = cleanedInput.indexOf('.')

    if (cleanedInput.length === 10 && cleanedInput.endsWith('.')) {
      cleanedInput = cleanedInput.substring(0, cleanedInput.length - 1)
    }

    if (dotIndex !== -1) {
      const digitsAfterDot = cleanedInput.length - dotIndex - 1
      if (digitsAfterDot > 2) {
        cleanedInput = cleanedInput.substring(0, dotIndex + 3)
      }
    }
    return cleanedInput
  }

  const getShortHand = (text: string): string => {
    var result = ''
    switch (text) {
      case 'Square Meters (sqm)':
        result = 'sqm'
        break
      case 'Square Feet':
        result = 'sq ft'
        break
      case 'Cubic Meters':
        result = 'm³'
        break
      default:
        return ''
    }
    return result
  }

  const onSubmit = (values: BuildingReplacementRate) => {
    updateBuildingReplacementRate(values, selectedData.id).then((response) => {
      if (response) {
        setSelectedData({
          ...selectedData,
          ...values,
        })
        showToastrNotification('Building Replacement Rate Saved succesfully', 'success')
        setIsEdit(false)
      } else {
        showToastrNotification('Error Saving Building Replacement Rate. Please try again.', 'error')
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionTitleHeader
        title='Building Replacement Rate'
        isEdit={isEdit}
        onEdit={() => setIsEdit(true)}
        onCancel={() => {
          reset({...selectedData})
          setIsEdit(false)
        }}
        permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
      />
      <div id='buildingReplacementRate'>
        <div className='row h-auto'>
          <div className='col-4 d-flex flex-column justify-content-between'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='valuationRegion'>
                Valuation Region
              </label>
              {isEdit ? (
                <Controller
                  control={control}
                  name='valuationRegion'
                  render={({field: {onChange, value}}) => (
                    <div>
                      <Select
                        placeholder='Select Valuation Region'
                        id='valuationRegion'
                        isMulti={false}
                        options={valuationRegionOptions}
                        value={{
                          value,
                          label: value,
                        }}
                        onChange={(e) => {
                          onChange(e?.value)
                          clearErrors('valuationRegion')
                        }}
                        className={`controllerSelect${
                          errors.valuationRegion ? ' border-danger' : ''
                        }`}
                      />
                      {errors?.valuationRegion && (
                        <small className='text-danger'>{errors?.valuationRegion.message}</small>
                      )}
                    </div>
                  )}
                />
              ) : (
                <div>{getValues('valuationRegion')}</div>
              )}
            </div>

            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='locationBase'>
                Location Base
              </label>
              {isEdit ? (
                <Controller
                  control={control}
                  name='locationBase'
                  render={({field: {onChange, value}}) => (
                    <div>
                      <Select
                        placeholder='Select Location Base'
                        id='locationBase'
                        isMulti={false}
                        options={locationOptions}
                        isDisabled={locationOptions.length === 0}
                        value={{
                          value,
                          label: value,
                        }}
                        onChange={(e) => {
                          onChange(e?.value)
                          clearErrors('locationBase')
                        }}
                        className={`controllerSelect${errors.locationBase ? ' border-danger' : ''}`}
                      />
                    </div>
                  )}
                />
              ) : (
                <div>{getValues('locationBase')}</div>
              )}
              {errors?.locationBase && (
                <small className='text-danger'>{errors?.locationBase.message}</small>
              )}
            </div>
          </div>
          <div className='col-4  d-flex flex-column justify-content-between'>
            <div className='mb-4'>
              <label className='fw-bolder' htmlFor='mainAreaNum'>
                Main Area
              </label>
              <div className='row'>
                <div className='col-6'>
                  {isEdit ? (
                    <input
                      style={{
                        fontSize: '1rem',
                      }}
                      maxLength={4}
                      type='text'
                      id='mainAreaNum'
                      className={`form-control${errors.mainAreaNum ? ' border-danger' : ''}`}
                      {...register('mainAreaNum', {
                        onChange: (e) => {
                          const value = formatNumberInput(e.target.value)
                          setValue('mainAreaNum', value)
                          clearErrors('mainAreaNum')
                        },
                      })}
                    />
                  ) : (
                    <div>
                      {getValues('mainAreaNum') + ' ' + getShortHand(getValues('mainAreaType'))}
                    </div>
                  )}
                  {errors?.mainAreaNum && (
                    <small className='text-danger'>{errors?.mainAreaNum.message}</small>
                  )}
                </div>

                <div className='col-6'>
                  <div className='form-outline'>
                    {isEdit && (
                      <Controller
                        control={control}
                        name='mainAreaType'
                        render={({field: {onChange, value}}) => (
                          <div>
                            <Select
                              id='mainAreaType'
                              isMulti={false}
                              options={mainAreaOptions}
                              value={{
                                value,
                                label: value,
                              }}
                              onChange={(e) => {
                                onChange(e?.value)
                                clearErrors('mainAreaType')
                              }}
                              className={`controllerSelect${
                                errors.mainAreaType ? ' border-danger' : ''
                              }`}
                            />
                            {errors.mainAreaType && (
                              <small className='text-danger'>{errors.mainAreaType.message}</small>
                            )}
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='locationPremium'>
                    Location Premium
                  </label>
                  {isEdit ? (
                    <Controller
                      control={control}
                      name='locationPremiumAdjustment'
                      render={({field: {onChange, value}}) => (
                        <div>
                          <Select
                            id='locationPremiumAdjustment'
                            isMulti={false}
                            options={regionIndexOptions}
                            isDisabled={regionIndexOptions.length === 0}
                            value={{
                              value: value,
                              label: value,
                            }}
                            onChange={(e) => {
                              onChange(e?.value)
                              clearErrors('locationPremiumAdjustment')
                            }}
                            className={`controllerSelect${
                              errors.locationPremiumAdjustment ? ' border-danger' : ''
                            }`}
                          />
                        </div>
                      )}
                    />
                  ) : (
                    <div>{getValues('locationPremiumAdjustment')}</div>
                  )}
                  {errors?.locationPremiumAdjustment && (
                    <small className='text-danger'>
                      {errors?.locationPremiumAdjustment.message}
                    </small>
                  )}
                </div>
              </div>
              <div className='col-6' />
            </div>
          </div>
          <div className='col-4  d-flex flex-column justify-content-between'>
            <div className='mb-4'>
              <label className='fw-bolder' htmlFor='secondaryAreaNum'>
                Secondary Area
              </label>
              <div className='row'>
                <div className='col-6'>
                  {isEdit ? (
                    <input
                      style={{
                        fontSize: '1rem',
                      }}
                      maxLength={4}
                      type='text'
                      id='secondaryQuantity'
                      className={`form-control${errors.secondaryQuantity ? ' border-danger' : ''}`}
                      {...register('secondaryQuantity', {
                        onChange: (e) => {
                          const value = formatNumberInput(e.target.value)
                          setValue('secondaryQuantity', value)
                          clearErrors('secondaryQuantity')
                        },
                      })}
                    />
                  ) : (
                    <div>
                      {getValues('secondaryQuantity') +
                        ' ' +
                        getShortHand(getValues('secondaryAreaType'))}
                    </div>
                  )}
                  {errors?.secondaryQuantity && (
                    <small className='text-danger'>{errors?.secondaryQuantity.message}</small>
                  )}
                </div>
                <div className='col-6'>
                  <div className='form-outline'>
                    {isEdit && (
                      <Controller
                        control={control}
                        name='secondaryAreaType'
                        render={({field: {onChange, value}}) => (
                          <div>
                            <Select
                              id='secondaryAreaType'
                              isMulti={false}
                              options={secondaryAreaOptions}
                              value={{
                                label: value,
                                value,
                              }}
                              onChange={(e) => {
                                onChange(e?.value)
                                clearErrors('secondaryAreaType')
                              }}
                              className={`controllerSelect${
                                errors.secondaryAreaType ? ' border-danger' : ''
                              }`}
                            />
                            {errors.secondaryAreaType && (
                              <small className='text-danger'>
                                {errors.secondaryAreaType.message}
                              </small>
                            )}
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className='form-outline'>
                  <label className='fw-bolder' htmlFor='timeIndex'>
                    Time Index
                  </label>
                  {isEdit ? (
                    <input
                      style={{
                        fontSize: '1rem',
                      }}
                      maxLength={4}
                      type='text'
                      id='timeIndex'
                      className={`form-control${errors.timeIndex ? ' border-danger' : ''}`}
                      {...register('timeIndex', {
                        onChange: (e) => {
                          const value = formatNumberInput(e.target.value)
                          setValue('timeIndex', value)
                          clearErrors('timeIndex')
                        },
                      })}
                    />
                  ) : (
                    <div>{getValues('timeIndex')}</div>
                  )}

                  {errors?.timeIndex && (
                    <small className='text-danger'>{errors?.timeIndex.message}</small>
                  )}
                </div>
              </div>
              <div className='col-6' />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default BuildingReplacementRates
