import React from "react"
import { ProjectAssetData } from "../../../models/project-assets"
import InsuranceForm from "./insurance-valuation/InsuranceForm/InsuranceForm"

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
}

function InsuranceValuationTab(props: IAssetInformationTabProps) {
  return (
   <React.Fragment>
        <InsuranceForm assetData={props.assetData}></InsuranceForm>
   </React.Fragment>
  )
}

export default InsuranceValuationTab