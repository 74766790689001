import {useCallback, useContext, useState} from 'react'
import {
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  Control,
  UseFormSetValue,
} from 'react-hook-form'
import Select from 'react-select'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {
  RadioGroup,
  Checkbox,
  RadioGroupChangeEvent,
  CheckboxChangeEvent,
} from '@progress/kendo-react-inputs'
import TitleHeader from '../../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import {ProjectContext} from '../../../../../../../context/ProjectContext'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import {IOption} from '../../../../../../../models/project-model'
import {transformDataOptions} from '../../../../../../../transformers/project-transformer'
import {getAssetClassComponentOptions} from '../../../../../../../api/asset-api'

/**DUMMY */
export const fields = [
  {value: 'assetNumber', label: 'Asset ID'},
  {value: 'assetDescription', label: 'Asset Name'},
  {value: 'status', label: 'Status'},
]

const asbestosStatusOptions = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'},
]

const valuationType = [
  {
    label: 'Single Line',
    value: 'Single Line',
  },
  {
    label: 'Componentized',
    value: 'Componentized',
  },
]

type IAssetInformationProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData[]) => void
}

function AssetInformation(props: IAssetInformationProps) {
  const {register, control, errors, clearErrors, setValue} = props
  const {assetClassOptions} = useContext(ProjectContext)
  const [isAssetClassComponentOptionLoading, setIsAssetClassComponentOptionLoading] =
    useState(false)
  const [assetClassComponentOptions, setAssetClassComponentOptions] = useState<IOption[]>([])

  const customStyles = {
    menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
    menu: (provided: any) => ({...provided, zIndex: 9999}),
  }

  const fetchAssetClassComponentOptions = useCallback((assetClassComponent: string) => {
    setIsAssetClassComponentOptionLoading(true)
    getAssetClassComponentOptions(assetClassComponent)
      .then((response: any) => {
        const valuers = transformDataOptions(response.data)
        setAssetClassComponentOptions(valuers)
        setIsAssetClassComponentOptionLoading(false)
      })
      .catch(() => {
        setIsAssetClassComponentOptionLoading(false)
      })
  }, [])

  const handleAssetClassDropdownChange = (e: any) => {
    fetchAssetClassComponentOptions(e?.value)
    setValue('assetType', '')
  }

  return (
    <>
      <TitleHeader title='Asset Information' />
      <div id='assetInformation'>
        <div className='row h-auto'>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='assetClass'>
                Asset Class
              </label>
              <Controller
                control={control}
                name='assetClass'
                render={({field: {onChange, value}}) => {
                  return (
                    <div>
                      <Select
                        placeholder='Select Asset Class'
                        styles={customStyles}
                        id='assetClass'
                        isMulti={false}
                        options={assetClassOptions}
                        value={{
                          label: value,
                          value: value,
                        }}
                        onChange={(e) => {
                          onChange(e?.value)
                          handleAssetClassDropdownChange(e)
                          clearErrors('assetClass')
                        }}
                        className={`controllerSelect${errors.assetClass ? ' border-danger' : ''}`}
                      />
                      {errors.assetClass && (
                        <small className='text-danger'>{errors.assetClass.message}</small>
                      )}
                    </div>
                  )
                }}
              />
            </div>
            <div className='form-outline'>
              <label className='fw-bolder' htmlFor='valuationDate'>
                Valuation Date
              </label>

              <>
                <Controller
                  control={control}
                  name='valuationDate'
                  render={({field: {onChange, value, onBlur}}) => {
                    return (
                      <DatePicker
                        value={value ? new Date(value) : null}
                        onBlur={onBlur}
                        format={'dd-MMM-yyyy'}
                        formatPlaceholder='formatPattern'
                        className={`controllerSelect${
                          errors.valuationDate ? ' border-danger' : ''
                        }`}
                        onChange={(e) => {
                          onBlur()
                          clearErrors('valuationDate')
                          onChange(e.target.value)
                        }}
                      />
                    )
                  }}
                />
                {errors.valuationDate && (
                  <small className='text-danger'>{errors.valuationDate.message}</small>
                )}
              </>
            </div>
          </div>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='assetType'>
                Asset Type
              </label>
              <Controller
                control={control}
                name='assetType'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Asset Type'
                      id='assetType'
                      isMulti={false}
                      options={assetClassComponentOptions}
                      value={{
                        label: value,
                        value: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('assetType')
                      }}
                      className={`controllerSelect${errors.assetType ? ' border-danger' : ''}`}
                    />
                    {errors.assetType && (
                      <small className='text-danger'>{errors.assetType.message}</small>
                    )}
                  </div>
                )}
              />
            </div>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='valuationType'>
                Valuation Type
              </label>
              {errors?.valuationType && (
                <div>
                  <small className='text-danger'>{errors?.valuationType.message}</small>
                </div>
              )}
              <Controller
                control={control}
                name='valuationType'
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <RadioGroup
                      value={value}
                      data={valuationType}
                      onChange={(e: RadioGroupChangeEvent) => {
                        clearErrors('valuationType')
                        onChange(e.value)
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='australisClassification'>
                Australis Classification
              </label>
              <input
                style={{
                  fontSize: '1rem',
                }}
                type='text'
                id='australisClassification'
                className={`form-control${errors.australisClassification ? ' border-danger' : ''}`}
                placeholder='Australis Classification'
                {...register('australisClassification', {
                  onChange: (e: any) => {
                    clearErrors('australisClassification')
                  },
                })}
              />
              {errors?.australisClassification ? (
                <div style={{height: '30px'}}>
                  <small className='text-danger'>{errors?.australisClassification.message}</small>
                </div>
              ) : (
                <div style={{height: '30px'}} />
              )}
            </div>
            <div className='form-outline'>
              <Controller
                control={control}
                name='insuranceValuation'
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Checkbox
                      label={'Insurance Valuation'}
                      name='insuranceValuation'
                      checked={value}
                      onChange={(e: CheckboxChangeEvent) => {
                        clearErrors('insuranceValuation')
                        onChange(!value)
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>
          <div className='col-3'>
            <div className='form-outline mb-4'>
              <label className='fw-bolder' htmlFor='asbestosStatus'>
                Asbestos Status
              </label>
              <Controller
                control={control}
                name='asbestosStatus'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Asset Type'
                      isMulti={false}
                      options={asbestosStatusOptions}
                      value={{
                        label: value,
                        value: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('asbestosStatus')
                      }}
                      className={`controllerSelect${errors.asbestosStatus ? ' border-danger' : ''}`}
                    />
                    {errors.asbestosStatus ? (
                      <div style={{height: '30px'}}>
                        <small className='text-danger'>{errors.asbestosStatus?.message}</small>
                      </div>
                    ) : (
                      <div style={{height: '30px'}} />
                    )}
                  </div>
                )}
              />
            </div>
            <div className='form-outline'>
              <Controller
                control={control}
                name='marketApproach'
                render={({field: {onChange, value, onBlur}}) => {
                  return (
                    <Checkbox
                      label={'Market Approach'}
                      name='marketApproach'
                      checked={value}
                      onChange={(e: any) => {
                        clearErrors('marketApproach')
                        // onChange(EVALUATION_TYPE.marketApproarch)
                        onChange(!value)
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssetInformation
