import {useCallback, useContext, useEffect, useState} from 'react'
import {
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  Control,
  UseFormSetValue,
  useForm,
} from 'react-hook-form'

import Select from 'react-select'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {
  RadioGroup,
  Checkbox,
  RadioGroupChangeEvent,
  CheckboxChangeEvent,
} from '@progress/kendo-react-inputs'
import {ProjectAssetData} from '../../../models/project-assets'
import {ProjectContext} from '../../../context/ProjectContext'
import {IOption} from '../../../models/project-model'
import {getAssetClassComponentOptions, updateProjectAssetInfo} from '../../../api/asset-api'
import {transformDataOptions} from '../../../transformers/project-transformer'
import SectionTitleHeader from '../SectionTitleHeader'
import {formatLongDate} from '../../../../../shared/service/utils'
import {yupResolver} from '@hookform/resolvers/yup'
import {assetInformationValidationSchema} from '../../../validators/asset-form'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

/**DUMMY */
export const fields = [
  {value: 'assetNumber', label: 'Asset ID'},
  {value: 'assetDescription', label: 'Asset Name'},
  {value: 'status', label: 'Status'},
]

const asbestosStatusOptions = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'},
]

const valuationType = [
  {
    label: 'Single Line',
    value: 'Single Line',
  },
  {
    label: 'Componentized',
    value: 'Componentized',
  },
]

type IAssetInformationProps = {
  id: number
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
  assetData: ProjectAssetData
  setAssetData: (value: ProjectAssetData) => void
}

function AssetInformation(props: IAssetInformationProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [assetClassOrigValue, setAssetClassOrigValue] = useState<string>('')
  const {assetData, setAssetData} = props
  const {assetClassOptions} = useContext(ProjectContext)
  const [isAssetClassComponentOptionLoading, setIsAssetClassComponentOptionLoading] =
    useState(false)
  const [assetClassComponentOptions, setAssetClassComponentOptions] = useState<IOption[]>([])
  const {showToastrNotification} = useContext(ProjectContext)

  const customStyles = {
    menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
    menu: (provided: any) => ({...provided, zIndex: 9999}),
  }

  const fetchAssetClassComponentOptions = useCallback((assetClassComponent: string) => {
    setIsAssetClassComponentOptionLoading(true)
    getAssetClassComponentOptions(assetClassComponent)
      .then((response: any) => {
        const valuers = transformDataOptions(response?.data)
        setAssetClassComponentOptions(valuers)
        setIsAssetClassComponentOptionLoading(false)
      })
      .catch(() => {
        setIsAssetClassComponentOptionLoading(false)
      })
  }, [])

  const handleAssetClassDropdownChange = (e: any) => {
    fetchAssetClassComponentOptions(e?.value)
    if (assetClassOrigValue !== e?.value) setValue('assetType', '')
    else setValue('assetType', assetData.assetType)
  }

  const handleOnEdit = () => {
    setIsEdit(true)
  }

  const handleOnCancel = () => {
    setIsEdit(false)
  }

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    register,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: assetData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(assetInformationValidationSchema) as any,
  })

  useEffect(() => {
    reset({...assetData})
    setAssetClassOrigValue(assetData.assetClass)
    fetchAssetClassComponentOptions(assetData.assetClass)
  }, [assetData])

  const onSubmit = (values: ProjectAssetData) => {
    updateProjectAssetInfo(values).then((response: any) => {
      if (response) {
        setAssetData(values)
        reset({...assetData})
        showToastrNotification('Asset Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
      }
      setIsEdit(false)
    })
  }

  const [australisClassificationLabel, setAustralisClassificationLabel] = useState<string>(
    'Australis Classification'
  )
  useEffect(() => {
    const handleResize = () => {
      if (window?.innerWidth <= 1440) {
        setAustralisClassificationLabel('Australis Class...')
      } else {
        setAustralisClassificationLabel('Australis Classification')
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window?.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitleHeader
          title='Asset Information'
          isEdit={isEdit}
          onEdit={handleOnEdit}
          onCancel={handleOnCancel}
          permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
        />
        <div id='assetInformation'>
          <div className='row h-auto'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='assetClass'>
                  Asset Class
                </label>
                {isEdit ? (
                  <Controller
                    control={control}
                    name='assetClass'
                    render={({field: {onChange, value}}) => {
                      return (
                        <div>
                          <Select
                            placeholder='Select Asset Class'
                            styles={customStyles}
                            id='assetClass'
                            isMulti={false}
                            options={assetClassOptions}
                            value={{
                              label: value,
                              value: value,
                            }}
                            onChange={(e) => {
                              onChange(e?.value)
                              handleAssetClassDropdownChange(e)
                              clearErrors('assetClass')
                            }}
                            className={`controllerSelect${
                              errors.assetClass ? ' border-danger' : ''
                            }`}
                          />
                          {errors.assetClass && (
                            <small className='text-danger'>{errors.assetClass.message}</small>
                          )}
                        </div>
                      )
                    }}
                  />
                ) : (
                  <p>{!assetData?.assetClass ? '-' : assetData?.assetClass}</p>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='assetType'>
                  Asset Type
                </label>
                {isEdit ? (
                  <Controller
                    control={control}
                    name='assetType'
                    render={({field: {onChange, value}}) => (
                      <div>
                        <Select
                          placeholder='Select Asset Type'
                          id='assetType'
                          isMulti={false}
                          options={assetClassComponentOptions}
                          value={{
                            label: value,
                            value: value,
                          }}
                          onChange={(e) => {
                            onChange(e?.value)
                            clearErrors('assetType')
                          }}
                          className={`controllerSelect${errors.assetType ? ' border-danger' : ''}`}
                        />
                        {errors.assetType && (
                          <small className='text-danger'>{errors.assetType.message}</small>
                        )}
                      </div>
                    )}
                  />
                ) : (
                  <p>{!assetData?.assetType ? '-' : assetData?.assetType}</p>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                <label
                  className='fw-bolder'
                  htmlFor='australisClassification'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Australis Classification'
                >
                  {australisClassificationLabel}
                </label>

                {isEdit ? (
                  <>
                    <input
                      style={{
                        fontSize: '1rem',
                      }}
                      type='text'
                      id='australisClassification'
                      className={`form-control${
                        errors.australisClassification ? ' border-danger' : ''
                      }`}
                      placeholder='Australis Classification'
                      {...register('australisClassification', {
                        onChange: (e: any) => {
                          clearErrors('australisClassification')
                        },
                      })}
                    />
                    {errors?.australisClassification ? (
                      <small className='text-danger'>
                        {errors?.australisClassification.message}
                      </small>
                    ) : null}
                  </>
                ) : (
                  <p>
                    {!assetData?.australisClassification ? '-' : assetData?.australisClassification}
                  </p>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='asbestosStatus'>
                  Asbestos Status
                </label>
                {isEdit ? (
                  <Controller
                    control={control}
                    name='asbestosStatus'
                    render={({field: {onChange, value}}) => (
                      <div>
                        <Select
                          placeholder='Select Asset Type'
                          isMulti={false}
                          options={asbestosStatusOptions}
                          value={{
                            label: value,
                            value: value,
                          }}
                          onChange={(e) => {
                            onChange(e?.value)
                            clearErrors('asbestosStatus')
                          }}
                          className={`controllerSelect${
                            errors.asbestosStatus ? ' border-danger' : ''
                          }`}
                        />
                        {errors?.asbestosStatus ? (
                          <small className='text-danger'>{errors?.asbestosStatus?.message}</small>
                        ) : null}
                      </div>
                    )}
                  />
                ) : (
                  <p>{!assetData?.asbestosStatus ? '-' : assetData?.asbestosStatus}</p>
                )}
              </div>
            </div>
          </div>
          <div className='row h-auto'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline  mb-4'>
                <label className='fw-bolder' htmlFor='valuationDate'>
                  Valuation Date
                </label>

                <>
                  {isEdit ? (
                    <>
                      <Controller
                        control={control}
                        name='valuationDate'
                        render={({field: {onChange, value, onBlur}}) => {
                          return (
                            <DatePicker
                              value={value ? new Date(value) : null}
                              onBlur={onBlur}
                              format={'dd-MMM-yyyy'}
                              formatPlaceholder='formatPattern'
                              className={`controllerSelect${
                                errors.valuationDate ? ' border-danger' : ''
                              }`}
                              onChange={(e) => {
                                onBlur()
                                clearErrors('valuationDate')
                                onChange(e.target.value)
                              }}
                            />
                          )
                        }}
                      />
                      {errors.valuationDate && (
                        <small className='text-danger'>{errors.valuationDate.message}</small>
                      )}
                    </>
                  ) : (
                    <p>
                      {!assetData?.valuationDate
                        ? '-'
                        : formatLongDate(new Date(assetData?.valuationDate).toLocaleDateString())}
                    </p>
                  )}
                </>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                <label className='fw-bolder' htmlFor='valuationType'>
                  Valuation Type
                </label>
                {errors?.valuationType && (
                  <div>
                    <small className='text-danger'>{errors?.valuationType.message}</small>
                  </div>
                )}
                {isEdit ? (
                  <>
                    {errors?.valuationType && (
                      <div>
                        <small className='text-danger'>{errors?.valuationType.message}</small>
                      </div>
                    )}
                    <Controller
                      control={control}
                      name='valuationType'
                      render={({field: {onChange, value, onBlur}}) => {
                        return (
                          <RadioGroup
                            value={value}
                            data={valuationType}
                            onChange={(e: RadioGroupChangeEvent) => {
                              clearErrors('valuationType')
                              onChange(e.value)
                            }}
                          />
                        )
                      }}
                    />
                  </>
                ) : (
                  <p>{!assetData?.valuationType ? '-' : assetData?.valuationType}</p>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                {!isEdit && (
                  <label className='fw-bolder' htmlFor='insuranceValuation'>
                    Insurance Valuation
                  </label>
                )}
                {isEdit ? (
                  <Controller
                    control={control}
                    name='insuranceValuation'
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Checkbox
                          label={'Insurance Valuation'}
                          name='insuranceValuation'
                          checked={value}
                          onChange={(e: CheckboxChangeEvent) => {
                            onChange(e)
                            clearErrors('insuranceValuation')
                            onChange(!value)
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  <p>{assetData?.insuranceValuation ? 'Yes' : 'No'}</p>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='form-outline mb-4'>
                {!isEdit && (
                  <label className='fw-bolder' htmlFor='marketApproach'>
                    Market Approach Required
                  </label>
                )}
                {isEdit ? (
                  <Controller
                    control={control}
                    name='marketApproach'
                    render={({field: {onChange, value, onBlur}}) => {
                      return (
                        <Checkbox
                          label={'Market Approach'}
                          name='marketApproach'
                          checked={value}
                          onChange={(e: CheckboxChangeEvent) => {
                            clearErrors('marketApproach')
                            onChange(!value)
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  <p>{assetData?.marketApproach ? 'Yes' : 'No'}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AssetInformation
