import React, {useState, useEffect} from 'react'
import {getInfoCards, getRecentProjects} from '../api'
import {useLayout} from '../../../../_metronic/layout/core'

export const ProjectListContext = React.createContext<any>({})

const initialDataState = {
  skip: 0,
  take: 10,
}

export const ProjectListContextProvider: React.FC = ({children}) => {
  const {selectedOffice} = useLayout()

  /**PROJECTS */
  const [projects, setProjects] = useState<any[]>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoadingProjects, setIsLoadingProjects] = useState(false)
  const [isLoadingInfoCard, setIsLoadingInfoCard] = useState(false)
  // const [pageSize, setPageSize] = useState(5)
  // const [totalPages, setTotalPages] = useState(1)
  // const [totalCount, setTotalCount] = useState(10)

  const fetchAllProjects = async (officeId: number) => {
    setIsLoadingProjects(true)
    const response = await getRecentProjects(officeId)
    setProjects(response.data)
    setIsLoadingProjects(false)
    // setTotalPages(response?.data?.totalPages)
    // setTotalCount(response?.data?.totalCount)
  }

  /**
   * PROJECT INFO CARDS
   */
  const [infoCards, setInfoCards] = useState()
  const fetchInfoCards = async (officeId: number) => {
    setIsLoadingInfoCard(true)
    const response = await getInfoCards(officeId)
    setInfoCards(response?.data)
    setIsLoadingInfoCard(false)
  }

  // useEffect(() => {
  //   fetchAllProjects()
  // }, [pageNumber, pageSize])
  let fetchDashboardQue: any
  useEffect(() => {
    // if (selectedOffice.id === 0) return
    setIsLoadingProjects(true)
    setIsLoadingInfoCard(true)
    if (fetchDashboardQue) {
      clearTimeout(fetchDashboardQue)
    }

    fetchDashboardQue = setTimeout(function () {
      fetchInfoCards(selectedOffice.id)
      fetchAllProjects(selectedOffice.id)
    })
  }, [selectedOffice])

  const handlePageChange = (event: any) => {
    const newPageNumber = event.page?.skip / event.page?.take + 1
    setPageNumber(newPageNumber)
  }

  return (
    <ProjectListContext.Provider
      value={{
        infoCards,
        projects,
        setProjects,
        // totalCount,
        // totalPages,
        handlePageChange,
        pageNumber,
        // pageSize,
        initialDataState,
        fetchAllProjects,
        // setTotalPages,
        // setTotalCount,
        isLoadingProjects,
        isLoadingInfoCard,
      }}
    >
      {children}
    </ProjectListContext.Provider>
  )
}
