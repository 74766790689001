import React, {useEffect, useState, useCallback} from 'react'
import Map, {Marker, NavigationControl} from 'react-map-gl/maplibre'
import '../ui/AssetInformation.scss'
import {Dialog} from '@progress/kendo-react-dialogs'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {ProjectAssetData} from '../../../models/project-assets'
import axios from 'axios'
import {createPortal} from 'react-dom'
import {AssetGalleries} from './AssetGalleries'
import {updateProjectAssetLocation} from '../../../api/asset-api'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'

type IAssetProfileProps = {
  assetData: ProjectAssetData
  setAssetData: (assetData: ProjectAssetData) => void
}

type AssetLocationMapModalProps = {
  address: string
  setAddress: (value: string) => void
  handleCancel: () => void
  onSubmit: () => void
  pinnedLocation: [number, number] | null
  setPinnedLocation: (value: [number, number] | null) => void
  fetchAddress: () => void
  loading: boolean
  setLoading: (value: boolean) => void
  longitude: number
  latitude: number
}

const MAPTILER_ENDPOINT = process.env.REACT_APP_MAPTILER_ENDPOINT
const MAPTILER_STYLE_KEY = process.env.REACT_APP_MAPTILER_STYLE_KEY

function AssetProfile(props: IAssetProfileProps) {
  const {assetData, setAssetData} = props

  /**
   * MAP
   */
  const [loading, setLoading] = useState(false)
  const [showMapModal, setShowMapModal] = useState<boolean>(false)
  const [pinnedLocation, setPinnedLocation] = useState<[number, number] | null>(null)
  const [address, setAddress] = useState<string>('')

  const handleMapClick = async (event: any) => {
    setShowMapModal(true)
  }

  const fetchAddress = useCallback(async () => {
    if (pinnedLocation) {
      try {
        setLoading(true)
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${
            pinnedLocation && pinnedLocation[1]
          }&lon=${pinnedLocation && pinnedLocation[0]}`
        )

        if (response.data.error === 'Unable to geocode') {
          setAddress('')
        } else {
          setAddress(response.data.display_name)
        }
      } catch (error) {
        console.error('Error fetching address:', error)
        return ''
      }
      setLoading(false)
    }
  }, [pinnedLocation])

  useEffect(() => {
    if (assetData.id > 0) {
      setPinnedLocation([assetData.longitude, assetData.latitude])
    }
  }, [assetData])

  useEffect(() => {
    fetchAddress()
  }, [pinnedLocation])

  const handleMapModalCancel = () => {
    setShowMapModal(false)
  }

  const onSubmit = () => {
    if (pinnedLocation) {
      const [long, lat] = pinnedLocation
      updateProjectAssetLocationAsync({
        ...assetData,
        latitude: lat,
        longitude: long,
      })
    }
  }

  const updateProjectAssetLocationAsync = useCallback(
    async (payload: ProjectAssetData) => {
      if (pinnedLocation) {
        try {
          setLoading(true)
          await updateProjectAssetLocation(payload)

          setAssetData(payload)
          setShowMapModal(false)
        } catch (error) {
          console.error('Error fetching address:', error)
          setLoading(false)
        }
        setLoading(false)
      }
    },
    [pinnedLocation]
  )

  return (
    <div className='asset-profile-container'>
      {/* ASSET Image */}
      <AssetGalleries assetData={assetData} setAssetData={setAssetData}></AssetGalleries>
      {/* ASSET Name */}
      <section>
        <div className='text-center asset-name'>
          <h1>{assetData?.assetName}</h1>
        </div>
      </section>
      {/* ASSET Description */}
      <section>
        <div className='fw-bold'>
          <label>Asset Description</label>
        </div>
        <div className='ms-5'>
          <div>{assetData?.assetDescription}</div>
        </div>
      </section>
      {/* AAAG ID*/}
      <section>
        <div className='fw-bold'>
          <label>AAAG ID</label>
        </div>
        <div className='ms-5'>
          <div>{!assetData?.aaagId ? 'N/A' : assetData?.aaagId}</div>
        </div>
      </section>
      {/* Client Asset ID*/}
      <section>
        <div className='fw-bold'>
          <label>Client Asset ID</label>
        </div>
        <div className='ms-5'>
          <div>
            {assetData.assetNumber === null || assetData.assetNumber.length === 0
              ? 'N/A'
              : assetData?.assetNumber}
          </div>
        </div>
      </section>
      {/* Assigned Valuer */}
      <section>
        <div className='fw-bold'>
          <label>Assigned Valuer</label>
        </div>
        <div className='ms-5'>
          <div className='d-flex'>
            <i
              className='bi bi-person-circle'
              style={{color: '#009ef6', fontSize: 18, marginRight: 10}}
            ></i>
            <div>{assetData.valuerName}</div>
          </div>
        </div>
      </section>
      {/* Estimated Construction Year */}
      <section>
        <div className='fw-bold'>
          <label>Estimated Construction Year</label>
        </div>
        <div className='ms-5'>
          <div>2024</div>
        </div>
      </section>
      {/* Asset Location */}
      <section>
        <div className='fw-bold'>
          <label>Asset Location</label>
        </div>

        {!showMapModal && pinnedLocation && (
          <div>
            {/* <GeoScapeMap  
                longitude={pinnedLocation[0]}
                latitude={pinnedLocation[1]}/> */}
            <Map
              onClick={handleMapClick}
              mapLib={maplibregl}
              initialViewState={{
                longitude: pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[0] : 0,
                latitude: pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[1] : 0,
                zoom: 5,
              }}
              style={{width: '100%', height: 150}}
              mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
            >
              <Marker
                longitude={pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[0] : 0}
                latitude={pinnedLocation && pinnedLocation.length > 0 ? pinnedLocation[1] : 0}
              />
            </Map>
          </div>
        )}
      </section>
      {/* MAP MODAL */}
      {showMapModal &&
        createPortal(
          <AssetLocationMapModal
            address={address}
            setAddress={setAddress}
            fetchAddress={fetchAddress}
            handleCancel={handleMapModalCancel}
            onSubmit={onSubmit}
            pinnedLocation={pinnedLocation}
            setPinnedLocation={setPinnedLocation}
            loading={loading}
            setLoading={setLoading}
            longitude={assetData.longitude}
            latitude={assetData.latitude}
          />,
          document.body
        )}
    </div>
  )
}

export default AssetProfile

export const AssetLocationMapModal = (props: AssetLocationMapModalProps) => {
  const {
    handleCancel,
    pinnedLocation,
    setPinnedLocation,
    address,
    //fetchAddress,
    setAddress,
    loading,
    longitude,
    latitude,
    onSubmit,
  } = props

  const handleMarkerDrag = (event: any) => {
    const {lng, lat} = event.lngLat
    setAddress('')
    setPinnedLocation([lng, lat])
  }

  const handleMarkerDragEnd = (event: any) => {
    const {lng, lat} = event.lngLat
    setAddress('')
    setPinnedLocation([lng, lat])
    // fetchAddress()
  }

  return (
    <Dialog
      height={'auto'}
      minWidth={'50%'}
      style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
      title={'Asset Location'}
      onClose={handleCancel}
    >
      <>
        <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic'}}>Pin a location on the map</p>
        {loading ? (
          <div className='d-flex alig-items-center justify-content-center mb-4'>
            <div className='spinner-border' role='status' style={{color: '#a71304'}}>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : !pinnedLocation && !address ? (
          <div style={{height: '50px'}}>
            <p style={{color: 'red', fontStyle: 'italic'}}>No location selected</p>
          </div>
        ) : (
          <div style={{height: '50px'}}>
            {pinnedLocation && address && (
              <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic', marginBottom: 0}}>
                {pinnedLocation && pinnedLocation[0]}, {pinnedLocation && pinnedLocation[1]}
              </p>
            )}
            {pinnedLocation && <p style={{color: 'red', fontStyle: 'italic'}}>{address}</p>}
          </div>
        )}
        <Map
          onClick={handleMarkerDrag}
          onDblClick={handleMarkerDrag}
          initialViewState={{
            longitude: longitude,
            latitude: latitude,
            zoom: 14,
          }}
          mapLib={maplibregl}
          style={{width: '100%', height: '400px'}}
          mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
        >
          <NavigationControl position='top-left' />
          {pinnedLocation && (
            <Marker
              longitude={pinnedLocation[0]}
              latitude={pinnedLocation[1]}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          )}
        </Map>
        <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
          <div className='d-flex flex-row align-items-center'>
            <Button buttonType='secondary' text={'Cancel'} onClick={handleCancel} />
            <Button
              buttonType='primary'
              text={'Save'}
              style={{marginLeft: 10}}
              onClick={onSubmit}
            />
          </div>
        </div>
      </>
    </Dialog>
  )
}
