import React, {useContext} from 'react'
import {Grid, GridColumn as Column, GridSortChangeEvent} from '@progress/kendo-react-grid'
import {gearIcon} from '@progress/kendo-svg-icons'
import {ActionButton} from '../../../../_metronic/layout/components/buttons/ActionButton'
import {ReportContext} from '../context/ReportContext'
import {downloadAaagMonthlyReport} from '../api/report-api'
import moment from 'moment'

function ReportsTable(props: any) {
  const {reports, handlePageChange, totalCount, gridSetup, setGridSetup, isLoading} =
    useContext(ReportContext)

  const items = ['Excel Report']

  const handleDropDownClick = async (dataItem: any, action: string) => {
    if (action === 'Excel Report') {
      try {
        const response = await downloadAaagMonthlyReport(dataItem.id)
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a link element, set its href and download attributes, and click it programmatically
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', dataItem.fileName)
        document.body.appendChild(link)
        link.click()

        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('Error downloading Excel file:', error)
        // Handle error as needed
      }
    }
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }
  return (
    <div className='m-auto mt-4'>
      {isLoading ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : !reports || (reports || []).length === 0 ? (
        <div className='align-middle text-center'>There are no reports to display!</div>
      ) : (
        <Grid
          data={reports}
          pageable={true}
          resizable={true}
          total={totalCount}
          onPageChange={handlePageChange}
          sortable={true}
          {...gridSetup}
          onSortChange={(e: GridSortChangeEvent) => {
            setGridSetup({...gridSetup, sort: e.sort})
          }}
        >
          <Column field='reportType' title='Report Type' headerCell={customHeaderCell} />
          <Column field='coverageMonth' title='Coverage Month' headerCell={customHeaderCell} />
          <Column
            field='lastModified'
            title='Created Date'
            headerCell={customHeaderCell}
            cell={(props) => {
              return (
                <td className='align-middle text-left'>
                  {moment(new Date(props.dataItem.lastModified)).format('DD-MMMM-YYYY')}
                </td>
              )
            }}
          />
          <Column
            title=''
            cell={(props) => (
              <td className='align-middle text-center'>
                <ActionButton
                  svgIcon={gearIcon}
                  text='Actions'
                  items={items}
                  onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
                />
              </td>
            )}
          />
        </Grid>
      )}
    </div>
  )
}

export default ReportsTable
