import React, {useCallback, useContext, useState} from 'react'
import {ProjectContext} from '../../../context/ProjectContext'
import {ProjectAssetData} from '../../../models/project-assets'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {marketApproachValidationSchema} from '../../../validators/asset-form'
import {useEffectOnce} from 'react-use'
import MarketApproachFairValueSection from '../../common/MarketApproachFairValue/MarketApproachFairValueSection'
import {IMarketApproachValuation} from '../../../models/market-approach-valuation'
import {MARKET_APPROACH_FORM_DEFAULT} from '../../../constant/market-approach-default'
import MarketEvidenceSection from '../../common/MarketEvidenceSection/MarketEvidenceSection'
import {
  getAdjustmentMatrixOptions,
  getMarketApproachValuationByProjectAssetId,
  saveMarketApproachValuation,
} from '../../../api/asset-api'
import {IAdjustmentMatrix} from '../../../models/adjustmentMatrix'
import {AccessControlProvider} from '../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
}

function MarketApproachValuationTab(props: IAssetInformationTabProps) {
  const {assetData} = props
  const {showToastrNotification} = useContext(ProjectContext)
  const [marketApproachData, setMarketApproachData] = useState<IMarketApproachValuation>({
    ...MARKET_APPROACH_FORM_DEFAULT,
    projectAssetId: assetData.id,
  })
  const [isAdjustmentMatrixOptionLoading, setIsAdjustmentMatrixOptionLoading] = useState(false)
  const [adjustmentMatrixOptions, setAdjustmentMatrixOptions] = useState<IAdjustmentMatrix[]>([])

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid},
    clearErrors,
    setValue,
    getValues,
    watch,
    //trigger,
    reset,
  } = useForm({
    defaultValues: marketApproachData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(marketApproachValidationSchema) as any,
  })

  useEffectOnce(() => {
    getMarketApproachValuationByProjectAssetId(assetData.id).then((result: any) => {
      setMarketApproachData(result.data)
      reset(result.data)
    })
    fetchAdjustmentMatrix()
  })

  const renderFormComponent = (
    Component: React.FC<any>,
    additionalProps: Record<string, ProjectAssetData> = {}
  ) => (
    <Component
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      isValid={isValid}
      clearErrors={clearErrors}
      getValues={getValues}
      setValue={setValue}
      watch={watch}
      selectedData={marketApproachData}
      setSelectedData={setMarketApproachData}
      adjustmentMatrixOptions={adjustmentMatrixOptions}
    />
  )

  const fetchAdjustmentMatrix = useCallback(() => {
    setIsAdjustmentMatrixOptionLoading(true)
    getAdjustmentMatrixOptions()
      .then((response: any) => {
        setAdjustmentMatrixOptions(response?.data)
        setIsAdjustmentMatrixOptionLoading(false)
      })
      .catch(() => {
        setIsAdjustmentMatrixOptionLoading(false)
      })
  }, [])

  const onSubmit = (values: IMarketApproachValuation) => {
    saveMarketApproachValuation(values).then((response: any) => {
      if (response) {
        setMarketApproachData(values)
        reset({...values})
        showToastrNotification('Market Approach Valuation Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Market Approach Valuation. Please try again.', 'error')
      }
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex justify-content-between'>
          <div className={`card bg-dark`}></div>
          <div className='d-flex align-items-start'>
            <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}>
              <Button text={'Save Changes'} buttonType='primary' />
            </AccessControlProvider>
          </div>
        </div>

        <div>
          <RowWrapper>
            <div className='col mt-4'>{renderFormComponent(MarketApproachFairValueSection)}</div>
          </RowWrapper>
        </div>
        <div>
          <RowWrapper>
            <div className='col'>{renderFormComponent(MarketEvidenceSection)}</div>
          </RowWrapper>
        </div>
      </form>
    </>
  )
}

export default MarketApproachValuationTab

export const RowWrapper = ({children}: React.PropsWithChildren<{example?: string}>) => {
  return <div className='row mb-8'>{children}</div>
}
