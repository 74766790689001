import React, {useCallback, useEffect, useContext, useState} from 'react'
import {UseFormHandleSubmit} from 'react-hook-form'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import axios from 'axios'
import {Dialog} from '@progress/kendo-react-dialogs'
import Map, {Marker} from 'react-map-gl/maplibre'
import Button from '../../../../../../../../../../_metronic/layout/components/buttons/Button'
import './AssetLocationMap.scss'
import {ProjectContext} from '../../../../../../../context/ProjectContext'

type AssetLocationMapModalProps = {
  handleCancel: () => void
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  onSubmit: () => void
}

const MAPTILER_ENDPOINT = process.env.REACT_APP_MAPTILER_ENDPOINT
const MAPTILER_STYLE_KEY = process.env.REACT_APP_MAPTILER_STYLE_KEY

export const AssetLocationMapModal = (props: AssetLocationMapModalProps) => {
  const [loading, setLoading] = useState(true)
  const {handleCancel, onSubmit} = props
  const {address, setAddress, pinnedLocation, setPinnedLocation, setRegion} =
    useContext(ProjectContext)

  const fetchAddress = useCallback(async () => {
    if (pinnedLocation) {
      try {
        setLoading(true)
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${
            pinnedLocation && pinnedLocation[1]
          }&lon=${pinnedLocation && pinnedLocation[0]}`
        )
        if (response.data.error === 'Unable to geocode') {
          setAddress('')
        } else {
          setRegion(response.data.address.municipality)
          setAddress(response.data.display_name)
        }
      } catch (error) {
        console.error('Error fetching address:', error)
        return ''
      }
      setLoading(false)
    }
  }, [pinnedLocation])

  useEffect(() => {
    fetchAddress()
    return () => {}
  }, [pinnedLocation])

  const handleMarkerDrag = (event: any) => {
    const {lng, lat} = event.lngLat
    setAddress('') // Clear previous address
    setPinnedLocation([lng, lat])
    clearTimeout(debounceTimeout)
  }

  const handleMarkerDragEnd = (event: any) => {
    const {lng, lat} = event.lngLat
    setAddress('') // Clear previous address
    setPinnedLocation([lng, lat])
    // fetchAddress()
    clearTimeout(debounceTimeout)
  }

  /** */
  let debounceTimeout: any
  const debounce = (func: any, delay: any) => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(func, delay)
  }

  const handleAddressSearch = useCallback(async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json`
      )

      if (response.data && response.data.length > 0) {
        const {lat, lon} = response.data[0]
        setAddress('') // Clear previous address
        setPinnedLocation([parseFloat(lon), parseFloat(lat)])
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error)
    }
    setLoading(false)
  }, [address, setPinnedLocation])

  const debouncedHandleAddressSearch = () => {
    debounce(handleAddressSearch, 300)
  }

  useEffect(() => {
    debouncedHandleAddressSearch()
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [])

  return (
    <Dialog
      height={'auto'}
      minWidth={'50%'}
      style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
      title={'Asset Location'}
      onClose={handleCancel}
    >
      <>
        <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic'}}>Pin a location on the map</p>

        {loading ? (
          <div className='d-flex alig-items-center justify-content-center mb-4'>
            <div className='spinner-border' role='status' style={{color: '#a71304'}}>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : !pinnedLocation && !address ? (
          <div style={{height: '50px'}}>
            <p style={{color: 'red', fontStyle: 'italic'}}>No location selected</p>
          </div>
        ) : (
          <div style={{height: '50px'}}>
            {pinnedLocation && address && (
              <p style={{color: 'rgb(66, 66, 66)', fontStyle: 'italic', marginBottom: 0}}>
                {pinnedLocation && pinnedLocation[0]}, {pinnedLocation && pinnedLocation[1]}
              </p>
            )}
            {pinnedLocation && <p style={{color: 'red', fontStyle: 'italic'}}>{address}</p>}
          </div>
        )}
        <Map
          onClick={handleMarkerDrag}
          onDblClick={handleMarkerDrag}
          initialViewState={{
            longitude: 146.73796758540948,
            latitude: -20.282642493114977,
            zoom: 4,
          }}
          style={{width: '100%', height: '400px'}}
          mapStyle={`${MAPTILER_ENDPOINT}/maps/streets/style.json?key=${MAPTILER_STYLE_KEY}`}
        >
          {pinnedLocation && (
            <Marker
              longitude={pinnedLocation[0]}
              latitude={pinnedLocation[1]}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          )}
        </Map>

        <div className='d-flex flex-row align-items-center justify-content-end w-100 mt-10'>
          <div className='d-flex flex-row align-items-center'>
            <Button buttonType='secondary' text={'Cancel'} onClick={handleCancel} />
            <Button
              buttonType='primary'
              text={'Save'}
              style={{marginLeft: 10}}
              onClick={onSubmit}
            />
          </div>
        </div>
      </>
    </Dialog>
  )
}
