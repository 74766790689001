import {FC, useCallback, useContext, useEffect, useState} from 'react'
import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'
import React from 'react'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import {Control, Controller, UseFormGetValues, useFieldArray} from 'react-hook-form'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import {ProjectAssetData} from '../../../models/project-assets'
import {ProjectContext} from '../../../context/ProjectContext'
import {IOption, IProjectCustomFieldOption} from '../../../models/project-model'
import {getProjectCustomFieldOptions} from '../../../api'
import {transformDataOptions} from '../../../transformers/project-transformer'
import {
  CurrencyFormatter,
  dateFormat,
  parseDdMmYyyyStringToDate,
} from '../../../../../shared/service/utils'
import {PROJECT_ASSET_CUSTOM_FIELD} from '../../../constant/project-asset-default'

type IProps = {
  // projectAssetCustomFields: FieldArrayWithId<
  //   ProjectAssetData,
  //   'projectAssetCustomFields',
  //   'keyIdx'
  // >[]
  // replaceProjectAssetCustomFields: UseFieldArrayReplace<
  //   ProjectAssetData,
  //   'projectAssetCustomFields'
  // >
  control: Control<ProjectAssetData>
  projectId: string
  isEdit: boolean
  getValues: UseFormGetValues<ProjectAssetData>
}

const ClientSpecificCustomFields: FC<IProps> = ({
  projectId,
  isEdit,
  control,
  getValues,
  // projectAssetCustomFields,
  // replaceProjectAssetCustomFields,
}) => {
  const {showToastrNotification} = useContext(ProjectContext)
  const [projectCustomFieldOptions, setProjectCustomFieldOptions] = useState<IOption[]>([])
  const [projectCustomFieldData, setProjectCustomFieldData] = useState<IProjectCustomFieldOption[]>(
    []
  )
  const {
    fields: projectAssetCustomFields,
    append: insertProjectAssetCustomField,
    update: updateProjectAssetCustomField,
    remove: removeProjectAssetCustomField,
  } = useFieldArray({
    control,
    name: 'projectAssetCustomFields',
    keyName: 'keyIdx',
  })

  const [hideCustomField, setHideCustomField] = useState(false)

  const fetchProjectCustomFieldOptions = useCallback((projectId: string) => {
    getProjectCustomFieldOptions(projectId)
      .then((response: any) => {
        const valuers = transformDataOptions(response.data)
        setHideCustomField(response.data.length === 0)
        setProjectCustomFieldOptions(valuers)
        setProjectCustomFieldData(response.data)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    fetchProjectCustomFieldOptions(projectId)
    // setData(projectAssetCustomFields)
  }, [projectAssetCustomFields])

  const customTextField = (props: GridCellProps) => {
    let selectedCustomField = projectCustomFieldData.filter(
      (item) => item.value === props.dataItem.projectCustomFieldId.toString()
    )
    let type = selectedCustomField.length === 0 ? '' : selectedCustomField[0].type

    if (!isEdit)
      return (
        <td width={'100%'}>
          {type === 'Currency'
            ? CurrencyFormatter(props.dataItem[props.field || ''])
            : props.dataItem[props.field || '']}
        </td>
      )

    if (type === 'Date') {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <DatePicker
                value={parseDdMmYyyyStringToDate(value)}
                format={'dd-MMM-yyyy'}
                formatPlaceholder='formatPattern'
                width={'100%'}
                onChange={(event) => {
                  onChange(dateFormat(event.target.value))
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: dateFormat(event.target.value),
                  })
                }}
              />
            )}
          />
        </td>
      )
    } else if (type === 'Conditional') {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <DropDownList
                value={value === 'Yes' ? 'Yes' : 'No'}
                data={['Yes', 'No']}
                style={{width: '100%'}}
                onChange={(event) => {
                  onChange(event)
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: event.target.value,
                  })
                }}
              />
            )}
          />
        </td>
      )
    } else if (type === 'Currency') {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <NumericTextBox
                format={`$#,##0.00`}
                placeholder='$0.00'
                value={+value}
                width={'100%'}
                onChange={(event) => onChange(event.target.value)}
                onBlur={() => {
                  // Force to trigger change
                  const value = getValues(`projectAssetCustomFields.${props.dataIndex}.value`)
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: value,
                  })
                }}
              />
            )}
          />
        </td>
      )
    } else {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <input
                type={type === 'Numeric' ? 'number' : 'text'}
                required={true}
                className='form-control'
                value={value}
                width={'100%'}
                onChange={(event) => onChange(event.target.value)}
                onBlur={(event) => {
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: event.target.value,
                  })
                }}
              />
            )}
          />
        </td>
      )
    }
  }

  const customDropdownField = (props: GridCellProps) => {
    const val = props.dataItem[props.field || ''].toString()
    if (!isEdit) {
      const d = projectCustomFieldOptions.find((x: any) => x.value === val)
      return <td>{d?.label}</td>
    }
    return (
      <td key={props.dataIndex}>
        <Controller
          control={control}
          name={`projectAssetCustomFields.${props.dataIndex}.projectCustomFieldId`}
          render={({field: {onChange, value, ref}}) => (
            <DropDownList
              style={{width: '100%'}}
              required={true}
              data={projectCustomFieldOptions.filter(
                (option) =>
                  !projectAssetCustomFields
                    .map((option) => option.projectCustomFieldId)
                    .find((cfield) => cfield.toString() === option.value)
              )}
              textField='label'
              dataItemKey='value'
              ref={ref}
              value={
                projectCustomFieldOptions.length > 0
                  ? projectCustomFieldOptions.find((x: any) => x.value === value.toString())
                  : null
              }
              onChange={(event: DropDownListChangeEvent) => {
                onChange(event.target.value.value)
                updateProjectAssetCustomField(props.dataIndex, {
                  ...props.dataItem,
                  [props.field || '']: +event.target.value.value,
                })
              }}
            />
          )}
        />
      </td>
    )
  }
  const customActionButton = (props: GridCellProps) => {
    return isEdit ? (
      <td key={props.dataIndex} className='text-center'>
        <i
          className='fa fa-times'
          onClick={(_) => {
            removeProjectAssetCustomField(props.dataIndex)
            showToastrNotification(
              'The field has been deleted. Your changes will take effect after you click Save Changes.',
              'info'
            )
          }}
        ></i>
      </td>
    ) : (
      <td></td>
    )
  }

  const addFieldHandler = () => {
    // if (!isValidField()) {
    //   showToastrNotification(
    //     'Please ensure no duplicate and all required fields are completed before proceeding to adding a new field.',
    //     'info'
    //   )
    //   return
    // }
    // var newdata = [
    //   ...data,
    //   {
    //     ...PROJECT_ASSET_CUSTOM_FIELD,
    //   },
    // ]
    insertProjectAssetCustomField({
      ...PROJECT_ASSET_CUSTOM_FIELD,
    })
  }

  // const isValidField = (): boolean => {
  //   const isValidInput =
  //     data.length === 0 ||
  //     data.filter(
  //       (item) =>
  //         item.projectCustomFieldId.toString().trim() === '' || item.value.toString().trim() === ''
  //     ).length === 0

  //   // Check for duplicate fields
  //   const fieldsSet = new Set<string>()
  //   const hasDuplicateField = data.some((item) => {
  //     const trimmedField = item.projectCustomFieldId.toString().toLowerCase().trim()
  //     if (fieldsSet.has(trimmedField)) {
  //       return true // Found a duplicate field
  //     } else {
  //       fieldsSet.add(trimmedField)
  //       return false
  //     }
  //   })

  //   return isValidInput && !hasDuplicateField
  // }

  return (
    <React.Fragment>
      {hideCustomField ? (
        <div
          className='d-flex justify-content-between rounded p-4'
          style={{background: 'rgb(255, 245, 248)', flex: 'calc(50% - 10px)'}}
        >
          'No Client Specific Fields defined on Project Settings'
        </div>
      ) : (
        <>
          {/* seperate grid for edit and view for issue column */}
          {!isEdit && (
            <Grid resizable={true} data={projectAssetCustomFields}>
              <Column
                field='projectCustomFieldId'
                width={350}
                title='Field Name'
                cell={customDropdownField}
              />
              <Column field='value' title='Value' cell={customTextField} />
            </Grid>
          )}
          {isEdit ? (
            <React.Fragment>
              <Grid resizable={true} data={projectAssetCustomFields}>
                <Column title=' ' width={60} cell={customActionButton} />
                <Column
                  field='projectCustomFieldId'
                  width={350}
                  title='Field Name'
                  cell={customDropdownField}
                />
                <Column field='value' title='Value' cell={customTextField} />
              </Grid>
              <button type='button' className='btn btn-primary mb-5 mt-5' onClick={addFieldHandler}>
                + Add Field
              </button>
            </React.Fragment>
          ) : null}
        </>
      )}
    </React.Fragment>
  )
}

export {ClientSpecificCustomFields}
