import {FC, useCallback, useContext, useEffect, useState} from 'react'
import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'
import React from 'react'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
} from 'react-hook-form'
import {ProjectContext} from '../../../../../../../context/ProjectContext'
import {ProjectAssetData} from '../../../../../../../models/project-assets'
import {PROJECT_ASSET_CUSTOM_FIELD} from '../../../../../../../constant/project-asset-default'
import {IOption, IProjectCustomFieldOption} from '../../../../../../../models/project-model'
import {transformDataOptions} from '../../../../../../../transformers/project-transformer'
import {useEffectOnce} from 'react-use'
import {getProjectCustomFieldOptions} from '../../../../../../../api'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {
  dateFormat,
  parseDdMmYyyyStringToDate,
} from '../../../../../../../../../shared/service/utils'
import {NumericTextBox} from '@progress/kendo-react-inputs'

type IProps = {
  control: Control<ProjectAssetData>
  projectId: string
  setValue: UseFormSetValue<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  getValues: UseFormGetValues<ProjectAssetData>
}

const ClientSpecificCustomFields: FC<IProps> = ({projectId, control, getValues}) => {
  const {showToastrNotification} = useContext(ProjectContext)
  const [projectCustomFieldOptions, setProjectCustomFieldOptions] = useState<IOption[]>([])
  const [projectCustomFieldData, setProjectCustomFieldData] = useState<IProjectCustomFieldOption[]>(
    []
  )
  const [hideCustomField, setHideCustomField] = useState(false)

  const {
    fields: projectAssetCustomFields,
    append: insertProjectAssetCustomField,
    update: updateProjectAssetCustomField,
    remove: removeProjectAssetCustomField,
  } = useFieldArray({
    control,
    name: 'projectAssetCustomFields',
    keyName: 'keyIdx',
  })

  const fetchProjectCustomFieldOptions = useCallback((projectId: string) => {
    getProjectCustomFieldOptions(projectId)
      .then((response: any) => {
        const valuers = transformDataOptions(response.data)
        setHideCustomField(response.data.length === 0)
        setProjectCustomFieldOptions(valuers)
        setProjectCustomFieldData(response.data)
      })
      .catch(() => {})
  }, [])

  useEffectOnce(() => {
    fetchProjectCustomFieldOptions(projectId)
  })

  useEffect(() => {}, [projectAssetCustomFields, projectCustomFieldOptions])

  const customTextField = (props: GridCellProps) => {
    let selectedCustomField = projectCustomFieldData.filter(
      (item) => item.value === props.dataItem.projectCustomFieldId.toString()
    )
    let type = selectedCustomField.length === 0 ? '' : selectedCustomField[0].type

    if (type === 'Date') {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <DatePicker
                value={parseDdMmYyyyStringToDate(value)}
                format={'dd-MMM-yyyy'}
                formatPlaceholder='formatPattern'
                onChange={(event) => {
                  onChange(dateFormat(event.target.value))
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: dateFormat(event.target.value),
                  })
                }}
              />
            )}
          />
        </td>
      )
    } else if (type === 'Currency') {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <NumericTextBox
                format={`$#,##0.00`}
                placeholder='$0.00'
                value={+value}
                onChange={(event) => onChange(event.target.value)}
                onBlur={() => {
                  // Force to trigger change
                  const value = getValues(`projectAssetCustomFields.${props.dataIndex}.value`)
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: value,
                  })
                }}
              />
            )}
          />
        </td>
      )
    } else if (type === 'Conditional') {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <DropDownList
                value={value === 'Yes' ? 'Yes' : 'No'}
                data={['Yes', 'No']}
                onChange={(event) => {
                  onChange(event)
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: event.target.value,
                  })
                }}
              />
            )}
          />
        </td>
      )
    } else {
      return (
        <td key={props.dataIndex}>
          <Controller
            control={control}
            name={`projectAssetCustomFields.${props.dataIndex}.value`}
            render={({field: {onChange, value}}) => (
              <input
                type={type === 'Numeric' ? 'number' : 'text'}
                required={true}
                className='form-control'
                value={value}
                onChange={(event) => onChange(event.target.value)}
                onBlur={(event) => {
                  updateProjectAssetCustomField(props.dataIndex, {
                    ...props.dataItem,
                    [props.field || '']: event.target.value,
                  })
                }}
              />
            )}
          />
        </td>
      )
    }
  }

  const customDropdownField = (props: GridCellProps) => {
    return (
      <td key={props.dataIndex}>
        <Controller
          control={control}
          name={`projectAssetCustomFields.${props.dataIndex}.projectCustomFieldId`}
          render={({field: {onChange, value, ref}}) => (
            <DropDownList
              style={{width: '100%'}}
              required={true}
              data={projectCustomFieldOptions.filter(
                (option) =>
                  !projectAssetCustomFields
                    .map((option) => option.projectCustomFieldId)
                    .find((cfield) => cfield.toString() === option.value)
              )}
              textField='label'
              dataItemKey='value'
              ref={ref}
              value={
                projectCustomFieldOptions.length > 0
                  ? projectCustomFieldOptions.find((x: any) => x.value === value.toString())
                  : null
              }
              onChange={(event: DropDownListChangeEvent) => {
                onChange(event.target.value.value)
                updateProjectAssetCustomField(props.dataIndex, {
                  ...props.dataItem,
                  [props.field || '']: +event.target.value.value,
                })
              }}
            />
          )}
        />
      </td>
    )
  }
  const customActionButton = (props: GridCellProps) => {
    return (
      <td key={props.dataIndex} className='text-center'>
        <i
          className='fa fa-times'
          onClick={(_) => {
            removeProjectAssetCustomField(props.dataIndex)
            showToastrNotification(
              'The field has been deleted. Your changes will take effect after you click Save Changes.',
              'info'
            )
          }}
        ></i>
      </td>
    )
  }

  const addFieldHandler = () => {
    insertProjectAssetCustomField({
      ...PROJECT_ASSET_CUSTOM_FIELD,
    })
  }

  return (
    <React.Fragment>
      {hideCustomField ? (
        <div
          className='d-flex justify-content-between rounded p-4'
          style={{background: 'rgb(255, 245, 248)', flex: 'calc(50% - 10px)'}}
        >
          'No Client Specific Fields defined on Project Settings'
        </div>
      ) : (
        <>
          <Grid resizable={true} data={projectAssetCustomFields} style={{width: '100%'}}>
            <Column title=' ' width={60} cell={customActionButton} />
            <Column field='projectCustomFieldId' title='Field' cell={customDropdownField} />
            <Column field='value' title='Value' cell={customTextField} />
          </Grid>
          <button type='button' className='btn btn-primary mb-5 mt-5' onClick={addFieldHandler}>
            + Add Field
          </button>
        </>
      )}
    </React.Fragment>
  )
}

export {ClientSpecificCustomFields}
