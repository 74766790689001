import React, {useContext, useState} from 'react'
import {FC} from 'react'

import {Control, Controller, FieldErrors, UseFormGetValues, UseFormRegister} from 'react-hook-form'
import {ClientContext} from '../../context/ClientContext'
import {IOffice} from '../../../office-list/models/office'
import PhoneInput1 from '../../../../../_metronic/partials/content/phone/PhoneInput1'
import {useEffectOnce} from 'react-use'
import {getUserOffices} from '../../../office-list/api'
import {useLayout} from '../../../../../_metronic/layout/core'

interface IProps {
  register: UseFormRegister<any>
  getValues: UseFormGetValues<any>
  errors: FieldErrors<any>
  control: Control<any>
}

const ClientFormFields: FC<IProps> = ({register, getValues, errors, control}) => {
  const {offices, fetchAllOffices} = useContext(ClientContext)
  const [officesOption, setOfficeOption] = useState<IOffice[]>(offices)
  const {selectedOffice} = useLayout()

  useEffectOnce(() => {
    const fetchData = async () => {
      const response = await getUserOffices()
      const filteredData = response.data.filter((item: any) => item.id === selectedOffice.id)

      setOfficeOption(filteredData)
    }

    fetchData()
  })

  return (
    <React.Fragment>
      {getValues('id') > 0 ? (
        <React.Fragment>
          <div className='text-center'>
            <h1>{getValues('name')}</h1>
          </div>
          <div className='text-center'>
            <h5>{getValues('shortCode')}</h5>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className='form-group mb-5'>
            <label>Name</label>
            <input
              type='name'
              className='form-control form-control-solid'
              placeholder='Enter Name'
              {...register('name')}
            />
            {errors.name && <small className='text-danger'>{errors.name?.message}</small>}
          </div>
          <div className='form-group mb-5'>
            <label>Short Code</label>
            <input
              type='address'
              className='form-control form-control-solid'
              placeholder='Enter Short Code'
              {...register('shortCode')}
            />
            {errors.shortCode && <small className='text-danger'>{errors.shortCode?.message}</small>}
          </div>
        </React.Fragment>
      )}

      <div className='form-group mb-5'>
        <label>Address</label>
        <input
          type='address'
          className='form-control form-control-solid'
          placeholder='Enter Address'
          {...register('address')}
        />
        {errors.address && <small className='text-danger'>{errors.address?.message}</small>}
      </div>
      <div className='form-group mb-5'>
        <label>Point of Contact</label>
        <input
          type='address'
          className='form-control form-control-solid'
          placeholder='Enter Point of Contact'
          {...register('pointContact')}
        />
        {errors.pointContact && (
          <small className='text-danger'>{errors.pointContact?.message}</small>
        )}
      </div>
      <div className='form-group mb-5'>
        <label>Phone Number</label>
        <Controller
          control={control}
          name='phoneNumber'
          render={({field: {value, onChange}}) => {
            return <PhoneInput1 phoneNumber={value} onChangeHandler={onChange}></PhoneInput1>
          }}
        />
        {errors.phoneNumber && <small className='text-danger'>{errors.phoneNumber?.message}</small>}
      </div>
      <div className='form-group mb-5'>
        <label>Email</label>
        <input
          type='address'
          className='form-control form-control-solid'
          placeholder='Enter Email'
          {...register('email')}
        />
        {errors.email && <small className='text-danger'>{errors.email?.message}</small>}
      </div>
      <div className='form-group mb-5'>
        <label>Office Assignment</label>
        <Controller
          control={control}
          name='officeId'
          render={({field: {onChange, value}}) => (
            <select
              style={{
                fontSize: '1rem',
                border: errors?.officeId ? '1px solid #f00' : '1px solid #E4E6EF',
              }}
              id='officeId'
              className='form-select'
              value={value}
              onChange={(event) => onChange(event.target.value)}
            >
              <option value={0}>Select Office</option>
              {officesOption?.map((office: IOffice) => (
                <option value={office.id} key={office.id}>
                  {office.name}
                </option>
              ))}
            </select>
          )}
        />
        {errors?.officeId && <small style={{color: '#f00'}}>Office assignment is required!</small>}
      </div>
      <button type='submit' className='btn btn-primary mb-6 w-100'>
        Save Changes
      </button>
    </React.Fragment>
  )
}
export {ClientFormFields}
