import {useCallback, useContext, useEffect, useState} from 'react'
import {
  Controller,
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  useForm,
} from 'react-hook-form'
import Select from 'react-select'
import {ProjectAssetData} from '../../../models/project-assets'
import {IOption} from '../../../models/project-model'
import {getInspectorOptions} from '../../../api'
import {transformDataOptions} from '../../../transformers/project-transformer'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {formatLongDate} from '../../../../../shared/service/utils'
import SectionTitleHeader from '../SectionTitleHeader'
import {updateProjectAssetInspectionDetails} from '../../../api/asset-api'
import {ProjectContext} from '../../../context/ProjectContext'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type InspectionDetailsProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<ProjectAssetData>
  errors: FieldErrors<ProjectAssetData>
  register: UseFormRegister<ProjectAssetData>
  clearErrors: UseFormClearErrors<ProjectAssetData>
  handleSubmit: UseFormHandleSubmit<ProjectAssetData>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
  projectId: string
  assetData: ProjectAssetData
}

const statusOptions = [
  {value: 'Additional Asset', label: 'Additional Asset'},
  {value: 'Inspected', label: 'Inspected'},
  {value: 'External Inspection Only', label: 'External Inspection Only'},
  {value: 'Inspected but Query', label: 'Inspected but Query'},
  {value: 'Drive-by Inspection Only', label: 'Drive-by Inspection Only'},
  {value: 'Excluded from Inspection', label: 'Excluded from Inspection'},
  {
    value: 'Excluded from Inspection - GPS Inaccurate',
    label: 'Excluded from Inspection - GPS Inaccurate',
  },
  {value: 'Unable to identify', label: 'Unable to identify'},
  {value: 'Demolished/Removed', label: 'Demolished/Removed'},
  {value: 'Inaccessible', label: 'Inaccessible'},
  {value: 'Not Inspected - Buildings', label: 'Not Inspected - Buildings'},
  {value: 'Not Inspected - GPS Inaccurate', label: 'Not Inspected - GPS Inaccurate'},
  {value: 'Not Inspected - Other Structures', label: 'Not Inspected - Other Structures'},
  {value: 'Not Inspected - Land', label: 'Not Inspected - Land'},
]

function InspectionDetails(props: InspectionDetailsProps) {
  const {selectedData, setSelectedData} = props
  const [inspectorOptions, setInspectorOptions] = useState<IOption[]>([])
  const {showToastrNotification} = useContext(ProjectContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [inspectorName, setInspectorName] = useState<string>('')

  const customStyles = {
    menuPortal: (provided: any) => ({...provided, zIndex: 9999}),
    menu: (provided: any) => ({...provided, zIndex: 9999}),
  }

  const fetchInspectorOptions = useCallback((projectId: string) => {
    getInspectorOptions(projectId)
      .then((response: any) => {
        const inspectors = transformDataOptions(response.data)
        setInspectorOptions(inspectors)
      })
      .catch(() => {})
  }, [])

  // useEffectOnce(() => {
  //   fetchInspectorOptions(props.projectId)
  // })

  useEffect(() => {
    if (props.projectId) fetchInspectorOptions(props.projectId)
  }, [props.projectId])

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    clearErrors,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (values: ProjectAssetData) => {
    updateProjectAssetInspectionDetails(values).then((response: any) => {
      if (response) {
        setSelectedData(values)

        showToastrNotification('Asset Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
      }
      setIsEdit(false)
    })
  }

  const handleOnEdit = () => {
    setIsEdit(true)
  }

  const handleOnCancel = () => {
    setIsEdit(false)
  }

  useEffect(() => {
    const inspectorSelected =
      inspectorOptions.length > 0
        ? inspectorOptions.find((x: any) => x.value === selectedData.inspector)
        : null

    setInspectorName(
      inspectorSelected === null || inspectorSelected === undefined ? '' : inspectorSelected.label
    )

    reset({...selectedData})
  }, [selectedData, inspectorOptions])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionTitleHeader
        title='Inspection Details'
        isEdit={isEdit}
        onEdit={handleOnEdit}
        onCancel={() => {
          handleOnCancel()
        }}
        permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
      />
      <div id='inspectionDetails'>
        <div className='col'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='status'>
              Inspection Status
            </label>
            {!isEdit ? (
              <p>{!selectedData?.status ? '-' : selectedData?.status}</p>
            ) : (
              <Controller
                control={control}
                name='status'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      styles={customStyles}
                      placeholder='Select Inspection Status'
                      id='status'
                      isMulti={false}
                      options={statusOptions}
                      value={{
                        value,
                        label: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('status')
                      }}
                      className={`controllerSelect${errors?.status ? ' border-danger' : ''}`}
                    />
                    {errors?.status && (
                      <small className='text-danger'>{errors?.status.message}</small>
                    )}
                  </div>
                )}
              />
            )}
          </div>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='inspectionDate'>
              Inspection Date
            </label>
            {!isEdit ? (
              <p>
                {!selectedData?.inspectionDate
                  ? '-'
                  : formatLongDate(new Date(selectedData?.inspectionDate).toLocaleDateString())}
              </p>
            ) : (
              <>
                <Controller
                  control={control}
                  name='inspectionDate'
                  render={({field: {onChange, value, onBlur}}) => {
                    return (
                      <DatePicker
                        value={value ? new Date(value) : null}
                        onBlur={onBlur}
                        format={'dd-MMM-yyyy'}
                        formatPlaceholder='formatPattern'
                        className={`controllerDatePicker${
                          errors?.inspectionDate ? ' border-danger' : ''
                        }`}
                        onChange={(e) => {
                          onBlur()
                          clearErrors('inspectionDate')
                          onChange(e.target.value)
                        }}
                      />
                    )
                  }}
                />
                {errors?.inspectionDate && (
                  <small className='text-danger'>{errors?.inspectionDate.message}</small>
                )}
              </>
            )}
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='inspector'>
              Inspection Name
            </label>
            {!isEdit ? (
              <p>{inspectorName}</p>
            ) : (
              <Controller
                control={control}
                name='inspector'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Inspection Name'
                      id='inspector'
                      isMulti={false}
                      options={inspectorOptions}
                      value={
                        inspectorOptions.length > 0
                          ? inspectorOptions.find((x: any) => x.value === value?.toString())
                          : null
                      }
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('inspector')
                      }}
                      className={`controllerSelect${errors?.inspector ? ' border-danger' : ''}`}
                    />
                    {errors?.inspector && (
                      <small className='text-danger'>{errors?.inspector.message}</small>
                    )}
                  </div>
                )}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default InspectionDetails
