import React, {useCallback, useContext, useEffect, useState} from 'react'
import {ProjectContext} from '../../../context/ProjectContext'
import {ProjectAssetData} from '../../../models/project-assets'
import Button from '../../../../../../_metronic/layout/components/buttons/Button'
import {getDistinctObjects} from '../../../../../shared/service/utils'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {singleLineValidationSchema} from '../../../validators/asset-form'
import {ISingleLineValutaion} from '../../../models/single-line-valuation'
import {SINGLELINE_FORM_DEFAULT} from '../../../constant/single-line-default'
import UnitRateSection from '../../common/UnitRateSection/UnitRateSection'
import FairValueSection from '../../common/FairValueSection/FairValueSection'
import PreviousReplacementCostSection from '../../common/PreviousReplacementCostSection/PreviousReplacementCostSection'
import {
  getAssetClassComponentOptions,
  getSingleLineValuationByProjectAssetId,
  saveSingleLineValuation,
} from '../../../api/asset-api'
import {
  transformDataOptions,
  transformDefaultOptions,
} from '../../../transformers/project-transformer'
import {IOption} from '../../../models/project-model'
import {useEffectOnce} from 'react-use'
import {formatNumberWithCommas} from '../../../transformers/project-transformer'
import {AccessControlProvider} from '../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IAssetInformationTabProps = {
  assetData: ProjectAssetData
}

function SingleLineValuationTab(props: IAssetInformationTabProps) {
  const {assetData} = props
  const {showToastrNotification} = useContext(ProjectContext)
  const [singleLineData, setSingleLineData] = useState<ISingleLineValutaion>({
    ...SINGLELINE_FORM_DEFAULT,
    projectAssetId: assetData.id,
  })
  const [fairValueFormat, setFairValueFormat] = useState<string>('0.00')
  const [isAssetClassComponentOptionLoading, setIsAssetClassComponentOptionLoading] =
    useState(false)
  const [assetClassComponentOptions, setAssetClassComponentOptions] = useState<IOption[]>([])
  const [locationOptions, setLocationOptions] = useState<IOption[]>([])
  // Client specific field
  const {regionIndexes} = useContext(ProjectContext)

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid},
    clearErrors,
    setValue,
    getValues,
    watch,
    //trigger,
    reset,
  } = useForm({
    defaultValues: singleLineData,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(singleLineValidationSchema) as any,
  })

  const watchedReplacementCost = watch('replacementCost')
  const watchedUsableLife = watch('usableLife')
  const watchedRemainingServicePotential = watch('remainingServicePotential')

  const computeFairValueTotal = () => {
    const fairValueTotal =
      getValues('usableLife') === 0
        ? 0
        : (parseFloat(getValues('replacementCost').toFixed(2)) /
            parseFloat(getValues('usableLife').toFixed(2))) *
          parseFloat(getValues('remainingServicePotential').toFixed(2))

    setFairValueFormat(formatNumberWithCommas(fairValueTotal))
    setValue('fairValueTotal', fairValueTotal)
  }

  useEffect(() => {
    computeFairValueTotal()
  }, [watchedReplacementCost, watchedUsableLife, watchedRemainingServicePotential])

  const fetchAssetClassComponentOptions = useCallback((assetClassComponent: string) => {
    setIsAssetClassComponentOptionLoading(true)
    getAssetClassComponentOptions(assetClassComponent)
      .then((response: any) => {
        const valuers = transformDataOptions(response?.data)
        setAssetClassComponentOptions(valuers)
        setIsAssetClassComponentOptionLoading(false)
      })
      .catch(() => {
        setIsAssetClassComponentOptionLoading(false)
      })
  }, [])

  useEffect(() => {
    let locBase = regionIndexes.filter((item: any) => item.stateCode === assetData.stateCode)
    let locBaseOption = transformDefaultOptions(locBase.map((res: any) => res.localGovernmentArea))
    let distinct: IOption[] = getDistinctObjects(locBaseOption, ['label', 'value'])
    setLocationOptions(distinct)
  }, [regionIndexes])

  useEffectOnce(() => {
    getSingleLineValuationByProjectAssetId(assetData.id).then((result: any) => {
      setSingleLineData(result.data)
      reset(result.data)
    })

    fetchAssetClassComponentOptions(assetData.assetClass)
  })

  const renderFormComponent = (
    Component: React.FC<any>,
    additionalProps: Record<string, ProjectAssetData> = {}
  ) => (
    <Component
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      isValid={isValid}
      clearErrors={clearErrors}
      getValues={getValues}
      setValue={setValue}
      watch={watch}
      selectedData={singleLineData}
      setSelectedData={singleLineData}
      assetClassComponentOptions={assetClassComponentOptions}
      locationOptions={locationOptions}
    />
  )

  const onSubmit = (values: ISingleLineValutaion) => {
    saveSingleLineValuation(values).then((response: any) => {
      if (response) {
        setSingleLineData(values)
        reset({...values})
        showToastrNotification('Single Line Valuation Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Single Line Valuation. Please try again.', 'error')
      }
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex justify-content-between'>
          <div className={`card bg-dark`}>
            <div className='card-body' style={{width: '350px'}}>
              <div className={`text-inverse-dark fw-bold fs-5 mb-2`}>Fair Value Total</div>
              <div
                className={`fw-bolder text-inverse-dark fs-1 text-right`}
                style={{textAlign: 'right'}}
              >
                {fairValueFormat}
              </div>
            </div>
          </div>
          <div className='d-flex align-items-start'>
            <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}>
              <Button text={'Save Changes'} buttonType='primary' />
            </AccessControlProvider>
          </div>
        </div>

        <div className='col mt-4'>
          <RowWrapper>
            <div className='col'>{renderFormComponent(UnitRateSection)}</div>
          </RowWrapper>
        </div>
        <div>
          <RowWrapper>
            <div className='col'>{renderFormComponent(FairValueSection)}</div>
          </RowWrapper>
        </div>
        <div>
          <RowWrapper>
            <div className='col'>{renderFormComponent(PreviousReplacementCostSection)}</div>
          </RowWrapper>
        </div>
      </form>
    </>
  )
}

export default SingleLineValuationTab

export const RowWrapper = ({children}: React.PropsWithChildren<{example?: string}>) => {
  return <div className='row mb-8'>{children}</div>
}
