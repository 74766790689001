import React, {useState, useContext} from 'react'
import {Grid, GridColumn as Column, GridSortChangeEvent} from '@progress/kendo-react-grid'
import {gearIcon} from '@progress/kendo-svg-icons'
import {Dialog} from '@progress/kendo-react-dialogs'
import {ActionButton} from '../../../../../_metronic/layout/components/buttons/ActionButton'
import {OfficeLocationsContext} from '../../context/OfficeLocationsContext'
import {deleteOffice} from '../../api'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import './OfficeLocationsListTable.css'

const initialSort: Array<any> = [{field: 'id', dir: 'desc'}]

function OfficeLocationsListTable(props: any) {
  const {
    offices,
    setOffices,
    setInitialValue,
    totalCount,
    handlePageChange,
    handleSortChange,
    gridSetup,
    fetchAllOffices,
    updateData,
    showToastrNotification,
    isLoading,
  } = useContext(OfficeLocationsContext)

  const items = ['View/Edit', 'Delete']
  const [sort, setSort] = useState<any>(initialSort)

  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null)

  const handleDropDownClick = (dataItem: any, action: string) => {
    if (action === 'View/Edit') {
      setInitialValue({...dataItem})
      props?.showOfficeModal(true)
      props?.setIsEdit(!props?.isEdit)
    } else if (action === 'Delete') {
      if (dataItem.id !== null) setSelectedDeleteId(dataItem.id)
    }
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteId(null)
  }

  const deleteItem = async (selectedDeleteId: any) => {
    try {
      await deleteOffice(selectedDeleteId).then((_) => {
        setOffices((prev: any) => prev.filter((office: any) => office?.id !== selectedDeleteId))
        setSelectedDeleteId(null)
        fetchAllOffices()
      })
      showToastrNotification('Office successfully deleted', 'success')
    } catch (error) {
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      setSelectedDeleteId(null)
    }
    updateData()
  }

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }

  return (
    <div className='m-auto mt-4' style={{width: '100%'}}>
      {/* DELETE MODAL */}
      {selectedDeleteId !== null && (
        <Dialog
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete Office'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              <p>Are you sure you want to delete this item?</p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col d-flex justify-content-end'>
              <Button
                onClick={() => deleteItem(selectedDeleteId)}
                buttonType='primary'
                text={'Yes'}
              />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => setSelectedDeleteId(null)}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}
      {isLoading ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : !offices || offices.length === 0 ? (
        <div className='align-middle text-center'>There are no offices to display!</div>
      ) : (
        <Grid
          data={offices}
          pageable={true}
          resizable={true}
          total={totalCount}
          onPageChange={handlePageChange}
          sort={sort}
          sortable={true}
          onSortChange={handleSortChange}
          {...gridSetup}
        >
          <Column
            field='code'
            title='Office Code'
            headerCell={customHeaderCell}
            cell={(props) => <td className='align-middle'>{props.dataItem[props.field || '']}</td>}
          />
          <Column
            field='name'
            title='Office Name'
            headerCell={customHeaderCell}
            cell={(props) => <td className='align-middle'>{props.dataItem[props.field || '']}</td>}
          />
          <Column
            field='completeAddress'
            title='Address'
            headerCell={customHeaderCell}
            cell={(props) => <td className='align-middle'>{props.dataItem[props.field || '']}</td>}
          />
          <Column
            field='status'
            title='Status'
            headerCell={customHeaderCell}
            // cell={() => (
            //   <td className='align-middle text-center'>
            //     <Switch onLabel={''} offLabel={''} size={'small'} />
            //   </td>
            // )}
            cell={(props) => <td className='align-middle'>{props.dataItem[props.field || '']}</td>}
          ></Column>
          <Column
            title=''
            cell={(cellprops) => (
              <td className='align-middle text-center'>
                <ActionButton
                  svgIcon={gearIcon}
                  text='Actions'
                  items={items}
                  onItemClick={(event: any) => handleDropDownClick(cellprops.dataItem, event.item)}
                />
              </td>
            )}
          />
        </Grid>
      )}
    </div>
  )
}

export default OfficeLocationsListTable
