import React from 'react'
import {
  Control,
  FieldErrors,
  UseFieldArrayReplace,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form'
import {IUserData} from '../../../models/user-model'
import UserGroupMemberInput from './UserGroupMemberInput'
import { IUserGroup } from '../../../models/user-group'

interface IProps {
  userData?: IUserData
  register: UseFormRegister<any>
  control: Control<any, any>
  errors: FieldErrors<any>
  userGroupOptions: any
  isDisabled: boolean
  clearErrors: UseFormClearErrors<any>
  groupMembers: any
  replaceMember: UseFieldArrayReplace<any, any>
  selectedUserGroupData: IUserGroup
}
const UserGroupFormFields: React.FC<IProps> = ({
  userData,
  register,
  errors,
  clearErrors,
  groupMembers,
  replaceMember,
  selectedUserGroupData
}) => {
  return (
    <React.Fragment>
      <div className='form-group'>
        <label className='form-label mb-3 fw-bold' htmlFor='name'>
          Group Name
        </label>
        <input
          type='text'
          className={`form-control${errors.name ? ' border-danger' : ''}`}
          placeholder='Enter Group Name'
          {...register('name', {
            onChange: () => {
              clearErrors('name')
            },
          })}
        />
        {errors.name && <small className='text-danger'>{errors.name?.message}</small>}
      </div>
      <div className='form-group'>
        <label className='form-label mb-3 mt-5 fw-bold' htmlFor='members'>
          Members
        </label>
        <UserGroupMemberInput
          groupName={selectedUserGroupData.name}
          groupMembers={groupMembers}
          onAddDeleteMember={(members) => {
            replaceMember([...members])
          }}
        ></UserGroupMemberInput>
      </div>
    </React.Fragment>
  )
}

export {UserGroupFormFields}
