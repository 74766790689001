import {useContext, useEffect, useState} from 'react'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import TitleHeader from '../../../../../_metronic/layout/components/header/TitleHeader'
//import UserInfoModal from '../Modal/UserModal/UserInfoModal'
//import UserGroupListTable from '../UserGroupList/UserGroupListTable'
import ProjectsListTable from './ProjectsListTable'
import {ProjectContext} from '../../context/ProjectContext'
import ProjectInfoModal from '../Modal/ProjectModal/ProjectInfoModal'
import {PROJECT_FORM_DEFAULT} from '../../constant/project-default'
import {hasPermission} from '../../../../shared/service/utils'
import {PAGE_PERMISSION} from '../../../roles/models/role-model'
import {usePageData} from '../../../../../_metronic/layout/core/PageData'

const ProjectManagementPage = () => {
  const {setModalVisible, setSearchFilter, setSelectedData, tableData, setIsLoading} =
    useContext(ProjectContext)

  const [searchText, setSearchText] = useState('')
  const {currentUser} = usePageData()

  const handleNew = () => {
    setSelectedData(PROJECT_FORM_DEFAULT)
    setModalVisible(true)
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSearchFilter(searchText)
    }
  }

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [tableData])

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 10,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      <TitleHeader title='My Projects' />
      <div className='d-flex align-items-center justify-content-between'>
        <div style={{position: 'relative'}}>
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onKeyDown={handleKeyPress}
            type='text'
            placeholder='Search Projects'
            style={{
              minWidth: 400,
              minHeight: 35,
              borderRadius: 17.5,
              border: '1px solid #E5E4E2',
              paddingLeft: 30,
              outline: 'none',
              fontStyle: 'italic',
            }}
          />
          <i
            className='bi bi-search'
            style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
          ></i>
        </div>
        <div className='d-flex align-items-center'>
          {hasPermission(PAGE_PERMISSION.PROJECT_CREATE, currentUser.activePages || []) && (
            <Button
              text={'New Project'}
              buttonType='primary'
              onClick={() => handleNew()}
              icon={<i className='bi bi-plus-lg' style={{color: '#fff'}}></i>}
            />
          )}
        </div>
      </div>
      <ProjectsListTable />
      <ProjectInfoModal />
    </div>
  )
}

export {ProjectManagementPage}
