import {useContext, useEffect, useState} from 'react'
import TitleHeader from '../../../../../_metronic/layout/components/header/TitleHeader'
import UserInfoModal from '../Modal/UserModal/UserInfoModal'
import UsersListTable from './UsersListTable'
import {UserContext} from '../../context/UserContext'
import {AccessControlProvider} from '../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../roles/models/role-model'
import {useLayout} from '../../../../../_metronic/layout/core'

const UserManagementPage = () => {
  const {
    tableData,
    setModalVisible,
    initialValue,
    setSearchFilter,
    setSelectedData,
    fetchData,
    isLoading,
    setIsLoading,
  } = useContext(UserContext)

  const [searchText, setSearchText] = useState('')
  const {selectedOffice} = useLayout()

  const handleNew = () => {
    setSelectedData(initialValue)
    setModalVisible(true)
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSearchFilter(searchText)
    }
  }

  useEffect(() => {
    if (searchText === '') {
      fetchData('', selectedOffice.id)
    }
  }, [searchText])

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [tableData])

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: 'auto',
        padding: 10,
        boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
      }}
    >
      {isLoading ? (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <TitleHeader title='Users List' />
          <div className='d-flex align-items-center justify-content-between'>
            <div style={{position: 'relative'}}>
              <input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
                onKeyDown={handleKeyPress}
                type='text'
                placeholder='Search Users'
                style={{
                  minWidth: 400,
                  minHeight: 35,
                  borderRadius: 17.5,
                  border: '1px solid #E5E4E2',
                  paddingLeft: 30,
                  outline: 'none',
                  fontStyle: 'italic',
                }}
              />
              <i
                className='bi bi-search'
                style={{position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)'}}
              ></i>
            </div>
            <div className='d-flex align-items-center'>
              <AccessControlProvider allowedPermission={PAGE_PERMISSION.USER_MANAGEMENT_CREATE}>
                {/* <Button
                  text={'New User'}
                  buttonType='primary'
                  onClick={() => handleNew()}
                  icon={<i className='bi bi-plus-lg' style={{color: '#fff'}}></i>}
                /> */}
              </AccessControlProvider>
            </div>
          </div>
          <UsersListTable />
          <UserInfoModal />
        </>
      )}
    </div>
  )
}

export {UserManagementPage}
