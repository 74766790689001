export const columns = [
  {field: 'aaagId', title: 'Asset ID', show: true},
  {field: 'assetName', title: 'Asset Name', show: true},
  {field: 'status', title: 'Status', show: true},
  {field: 'location', title: 'Location Premium', show: true},
  {field: 'address', title: 'Address', show: true},
  {field: 'suburb', title: 'Suburb', show: true},
  {field: 'stateCode', title: 'State', show: true},
  {
    field: 'id',
    title: 'ID',
    show: false,
  },
  {field: 'fulcrumId', title: 'Fulcrum ID', show: false},
  {field: 'projectId', title: 'Project ID', show: false},
  {field: 'locationCodeDesc', title: 'Location Code Desc', show: false},
  {field: 'assetClass', title: 'Asset Class', show: false},
  {field: 'assetType', title: 'Asset Type', show: false},
  {field: 'assetSubType', title: 'Asset SubType', show: false},
  {field: 'australisClassification', title: 'Australis Classification', show: false},
  {field: 'asbestosStatus', title: 'Asbestos Status', show: false},
  {field: 'valuationDate', title: 'Valuation Date', show: false},
  {field: 'valuationType', title: 'Valuation Type', show: false},
  {field: 'insuranceValuation', title: 'Insurance Valuation', show: false},
  {field: 'marketApproach', title: 'Market Approach', show: false},
  {field: 'inspectionDate', title: 'Inspection Date', show: false},
  {field: 'inspector', title: 'Inspector', show: false},
  {field: 'mainQuantity', title: 'Main Quantity', show: false},
  {field: 'secondaryQuantity', title: 'Secondary Quantity', show: false},
  {field: 'localGovernmentArea', title: 'Local Government Area', show: false},
  {field: 'locationPremiumAdjustment', title: 'Location Premium Adjustment', show: false},
  {field: 'timeIndex', title: 'Time Index', show: false},
  {field: 'distanceFromRoad', title: 'Distance From Road', show: false},
  {field: 'constructionAndSiteFactors', title: 'Site Factors', show: false},
  {field: 'hotWaterSystem', title: 'Hot Water System', show: false},
  {field: 'hotWaterSystemType', title: 'Hot Water System Type', show: false},
  {field: 'hvac', title: 'HVAC', show: false},
  {field: 'hvacType', title: 'HVAC Type', show: false},
  {field: 'ductedAcCoverage', title: 'Ducted AC Coverage', show: false},
  {field: 'countOfAcUnits', title: 'Count Of AC Units', show: false},
  {field: 'solar', title: 'Solar', show: false},
  {field: 'solarSystemSize', title: 'Solar System Total Size', show: false},
  {field: 'waterTank', title: 'Water Tank', show: false},
  {field: 'waterTankTotalSize', title: 'Water Tank Total Size', show: false},
  {field: 'tankStand', title: 'Tank Stand', show: false},
  {field: 'tankStandSize', title: 'Tank Stand Size', show: false},
  {field: 'septicSystem', title: 'Septic System', show: false},
  {field: 'septicSysteType', title: 'Septic System Type', show: false},
  {field: 'fireServices', title: 'Fire Services', show: false},
  {field: 'fireServicesPresent', title: 'Fire Services Present', show: false},
  {field: 'lift', title: 'Lift', show: false},
  {field: 'liftType', title: 'Lift Type', show: false},
  {field: 'liftSecondary', title: 'Lift Secondary', show: false},
  {field: 'liftSecondaryType', title: 'Lift Secondary Type', show: false},
  {field: 'alarmSystem', title: 'Alarm System', show: false},
  {field: 'alarmSystemSubtype', title: 'Alarm System Subtype', show: false},
  {field: 'assetNumberFromPriorWF', title: 'Asset Number From Prior WF', show: false},
  {field: 'priorFieldNotes', title: 'Prior Field Notes', show: false},
  {field: 'priorCopyComments', title: 'Prior Copy Comments', show: false},
  {field: 'priorRawlosRate', title: 'Prior Rawlos Rate', show: false},
  {field: 'priorAlternateRate', title: 'Prior Alternate Rate', show: false},
  {field: 'priorLocationPremium', title: 'Prior Location Premium', show: false},
  {field: 'priorMainRateAdjFactor', title: 'Prior Main Rate Adj Factor', show: false},
  {field: 'priorSecondRateAdjFactor', title: 'Prior Second Rate Adj Factor', show: false},
  {field: 'priorFVProfessionalFees', title: 'Prior FV Professional Fees', show: false},
  {field: 'aaagId', title: 'AAAG ID', show: false},
  {field: 'suburbLgaText', title: 'Suburb Lga Text', show: false},
  {field: 'region', title: 'Valuation Region', show: false},
]
