import React, {useState, useContext} from 'react'
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid'
import {gearIcon} from '@progress/kendo-svg-icons'
import {Dialog} from '@progress/kendo-react-dialogs'
import {ActionButton} from '../../../../../_metronic/layout/components/buttons/ActionButton'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {AssetContext} from './../../context/AssetContext'
import {useHistory} from 'react-router-dom'
import {transformAssetClass} from '../../transformer/asset-class-transformer'
import {deleteAssetClass} from '../../api/asset-class-api'
import './AssetClassLookupTable.scss'
import {Loader2} from '../../../../../_metronic/layout/components/loader/Loader2'
import {useEffectOnce} from 'react-use'
import {PAGE_PERMISSION} from '../../../roles/models/role-model'
import {hasPermission} from '../../../../shared/service/utils'
import {usePageData} from '../../../../../_metronic/layout/core/PageData'

const initialSort: {field: string; dir: string}[] = [{field: 'id', dir: 'desc'}]

function AssetClassLookupTable() {
  const {
    assets,
    setAssets,
    setComponentInitialValue,
    handlePageChange,
    gridSetup,
    updateData,
    showToastrNotification,
    totalRows,
    isLoading,
    handleSortChange,
  } = useContext(AssetContext)

  const history = useHistory()
  // const items = ['Update Asset Class', 'Delete Asset Class']
  const [sort, setSort] = useState<any>(initialSort)
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [items, setItems] = useState<string[]>([])
  const {
    currentUser: {activePages},
  } = usePageData()

  useEffectOnce(() => {
    let newActions = []
    if (hasPermission(PAGE_PERMISSION.ASSET_CLASSIFICATION_UPDATE, activePages || [])) {
      newActions.push('Update Asset Class')
    } else newActions.push('View Asset Class')

    if (hasPermission(PAGE_PERMISSION.ASSET_CLASSIFICATION_DELETE, activePages || [])) {
      newActions.push('Delete Asset Class')
    }

    setItems(newActions)
  })

  const handleDropDownClick = (dataItem: any, action: string) => {
    if (action === 'Update Asset Class') {
      const value = transformAssetClass({...dataItem})
      setComponentInitialValue({...value})
      history.push({pathname: `/asset-class/edit/${dataItem.id}`, state: {params: dataItem}})
    } else if (action === 'Delete Asset Class') {
      if (dataItem.id !== null) setSelectedDeleteId(dataItem.id)
    }
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDeleteId(null)
  }

  const deleteItem = async (selectedDeleteId: string | number) => {
    setIsSaving(true)
    try {
      deleteAssetClass(+selectedDeleteId)
        .then((result) => {
          setAssets((prev: any) => prev.filter((asset: any) => asset?.id !== selectedDeleteId))
          setIsSaving(false)
          showToastrNotification('Asset successfully deleted', 'success')
        })
        .catch(() => {
          setIsSaving(false)
          showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
        })
      setSelectedDeleteId(null)
    } catch (error) {
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      setSelectedDeleteId(null)
    }
    setIsSaving(false)
    updateData()
  }

  /** */
  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }

  return (
    <div className='m-auto mt-4'>
      {/* DELETE MODAL */}
      {isSaving && <Loader2></Loader2>}
      {selectedDeleteId !== null && (
        <Dialog
          style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
          title={'Delete Asset Class'}
          onClose={handleCloseDeleteDialog}
        >
          <div className='row mb-4'>
            <div className='col'>
              {/* <p>Are you sure you want to delete this item?</p> */}
              <p>You are deleting an Asset Class and its components, Continue?</p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col  d-flex justify-content-end'>
              <Button
                onClick={() => deleteItem(selectedDeleteId)}
                buttonType='primary'
                text={'Yes'}
              />
              <Button
                buttonType='secondary'
                text={'No'}
                onClick={() => setSelectedDeleteId(null)}
                style={{marginLeft: 10}}
              />
            </div>
          </div>
        </Dialog>
      )}
      {isLoading ? (
        <div className='d-flex alig-items-center justify-content-center'>
          <div className='spinner-border' role='status' style={{color: '#a71304'}}>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : !assets || assets.length === 0 ? (
        <div className='align-middle text-center'>There are no assets to display!</div>
      ) : (
        <Grid
          id='asset-class-lookup-table'
          data={assets}
          pageable={true}
          resizable={true}
          total={totalRows}
          onPageChange={handlePageChange}
          sort={sort}
          sortable={true}
          onSortChange={handleSortChange}
          {...gridSetup}
        >
          <Column
            field='name'
            title='Asset Name'
            headerCell={customHeaderCell}
            cell={(props) => <td className='align-middle text-center'>{props.dataItem.name}</td>}
          />
          <Column
            field='fairValue'
            title='Fair Value'
            headerCell={customHeaderCell}
            cell={(props) => (
              <td className='align-middle text-center'>
                {props.dataItem[props.field || ''] ? 'Yes' : 'No'}
              </td>
            )}
          />
          <Column
            field='insuranceValuation'
            title='Insurance Valuation'
            headerCell={customHeaderCell}
            cell={(props) => (
              <td className='align-middle text-center'>
                {props.dataItem[props.field || ''] ? 'Yes' : 'No'}
              </td>
            )}
          />

          <Column
            title=''
            cell={(props) => (
              <td className='align-middle text-center'>
                <ActionButton
                  svgIcon={gearIcon}
                  text='Actions'
                  items={items}
                  onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
                />
              </td>
            )}
          />
        </Grid>
      )}
    </div>
  )
}

export default AssetClassLookupTable
