import {useEffect, useState, useCallback, KeyboardEvent} from 'react'
import {createPortal} from 'react-dom'
import Button from '../../../../../../../../../_metronic/layout/components/buttons/Button'
import TitleHeader from '../../../../../../../../../_metronic/layout/components/header/TitleHeader'
import './ProjectAssets.css'
import ProjectAssetListTable from '../ProjectAssetListTable/ProjectAssetListTable'

import {Initial_GridSetup} from '../../../../../../constant/project-default'
import {GridSetup, IProjectData} from '../../../../../../models/project-model'
import {getProjectAssets} from '../../../../../../api'
import {toObjectExpression} from '../../../../../../../../shared/service/utils'
import NewAssetForm from '../NewAssetForm/NewAssetForm'
import {columns as columnsList} from '../ColumnsList/columns'
import BulkUpdateForm from './../BulkUpdateForm/BulkUpdateForm'
import {ProjectAssetData, ProjectData} from '../../../../../../models/project-assets'
import {Modal} from '../../../../../../../../../_metronic/layout/components/modal/Modal'
import {AccessControlProvider} from '../../../../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../../../../roles/models/role-model'

type IProjectAssetsProps = {
  projectData: IProjectData | ProjectData | undefined
}

const ProjectAssets = (props: IProjectAssetsProps) => {
  const {projectData} = props
  const fulcrumId = projectData?.fulcrumId || ''

  const [gridSetup, setGridSetup] = useState<GridSetup>(Initial_GridSetup)
  const [tableData, setTableData] = useState<ProjectAssetData[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const [columns, setColumns] =
    useState<{field: string; title: string; show: boolean}[]>(columnsList)

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const [isAssetModalVisible, setAssetModalVisible] = useState<boolean>(false)
  const [isBulkModalVisible, setBulkModalVisible] = useState<boolean>(false)
  const [selectedAssets, setSelectedAssets] = useState<any>([])

  const [isFulcrumIdNull, setIsFulcrumIdNull] = useState<boolean>(false)

  const handlePageChange = (pageProps: any) => {
    setGridSetup({
      ...gridSetup,
      skip: pageProps.page.skip,
      take: pageProps.page.take,
    })
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSearchFilter(searchText)
    }
  }

  const fetchProjectAssets = useCallback(
    (search: string = '') => {
      setIsLoading(true)

      const {sort, skip, take} = gridSetup
      //const data = toObjectExpression(gridSetup.filter)

      getProjectAssets(fulcrumId, skip, take, sort[0], search)
        .then((response: any) => {
          setTableData(
            response.data?.items?.map((asset: any) =>
              Object.assign(
                {
                  selected: false,
                },
                asset
              )
            )
          )
          setTotalRows(response.data.totalCount)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    },
    [gridSetup]
  )

  useEffect(() => {
    fetchProjectAssets(searchFilter)
  }, [gridSetup, fetchProjectAssets, searchFilter])

  const successBulkUpdateHandler = () => {
    fetchProjectAssets()
    setSelectedAssets([])
  }

  const saveAssetHandler = () => {
    fetchProjectAssets()
    setAssetModalVisible(false)
  }

  return (
    <>
      <div id='project_assets'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-content-center project_assets_spinner'>
            <div className='spinner-border' role='status' style={{color: '#a71304'}}>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <TitleHeader title='Asset List' />
            <div className='d-flex align-items-center justify-content-between'>
              <div style={{position: 'relative'}}>
                <input
                  className='project_assets_input'
                  value={searchText}
                  onChange={(e) => {
                    console.log('searchtext')
                    setSearchText(e.target.value)
                  }}
                  onKeyDown={handleKeyPress}
                  type='text'
                  placeholder='Search Asset'
                />
                <i className='bi bi-search project_assets_input_icon'></i>
              </div>
              <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                  <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}>
                    <Button
                      text={'Bulk Update'}
                      buttonType='primary'
                      onClick={() => {
                        setBulkModalVisible(true)
                      }}
                      style={{pointerEvents: selectedAssets?.length === 0 ? 'none' : 'auto'}}
                    />
                  </AccessControlProvider>
                </div>
                <div className='mx-2' />
                <div className='d-flex align-items-center'>
                  <AccessControlProvider allowedPermission={PAGE_PERMISSION.ASSETS_PAGE_CREATE}>
                    <Button
                      text={'New Asset'}
                      buttonType='primary'
                      onClick={() => {
                        if (!fulcrumId) {
                          setIsFulcrumIdNull(true)
                        } else {
                          setAssetModalVisible(true)
                        }
                      }}
                      icon={<i className='bi bi-plus-lg' style={{color: '#fff'}}></i>}
                    />
                  </AccessControlProvider>
                </div>
              </div>
            </div>

            <ProjectAssetListTable
              tableData={tableData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              totalRows={totalRows}
              fetchProjectAssets={fetchProjectAssets}
              gridSetup={gridSetup}
              setGridSetup={setGridSetup}
              columns={columns}
              setColumns={setColumns}
              handlePageChange={handlePageChange}
              selectedAssets={selectedAssets}
              setSelectedAssets={setSelectedAssets}
            />
          </>
        )}
      </div>
      {isAssetModalVisible &&
        !isFulcrumIdNull &&
        createPortal(
          <NewAssetForm
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setAssetModalVisible={setAssetModalVisible}
            fulcrumId={fulcrumId.toString()}
            onSaveAsset={saveAssetHandler}
            valuationRegion={projectData?.valuationRegion || ''}
            projectData={projectData}
          />,
          document.body
        )}
      {isFulcrumIdNull &&
        createPortal(
          <Modal
            isOpen={isFulcrumIdNull}
            closeModal={() => setIsFulcrumIdNull(false)}
            width={'400px'}
          >
            <span style={{textAlign: 'left'}}>
              Cannot create New Asset, Project Fulcrum ID is missing
            </span>
            <div className='mt-4 d-flex justify-content-end'>
              <Button
                onClick={() => {
                  setIsFulcrumIdNull(false)
                }}
                buttonType='primary'
                text={'Ok'}
              />
            </div>
          </Modal>,
          document.body
        )}
      {isBulkModalVisible &&
        createPortal(
          <BulkUpdateForm
            setBulkModalVisible={setBulkModalVisible}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
            successBulkUpdate={successBulkUpdateHandler}
          />,
          document.body
        )}
    </>
  )
}

export {ProjectAssets}
