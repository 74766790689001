import React, {useState, useContext} from 'react'
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid'
import {Dialog} from '@progress/kendo-react-dialogs'
import {orderBy} from '@progress/kendo-data-query'
import {gearIcon} from '@progress/kendo-svg-icons'
import {ActionButton} from '../../../../../_metronic/layout/components/buttons/ActionButton'
import Button from '../../../../../_metronic/layout/components/buttons/Button'
import {AssetContext} from '../../context/AssetContext'
import {ASSET_COMPONENT_INITIAL} from '../../constant/asset-constant'

type IAssetComponent = {
  assetData?: any
  setAssetData?: (value: any) => void
  isEdit?: boolean
  setIsEdit?: (value: boolean) => void
  setModalVisible?: (value: boolean) => void
}

const initialSort: {field: string; dir: string}[] = [{field: 'id', dir: 'desc'}]

function AssetComponent(props: IAssetComponent) {
  const {
    assetData,
    setAssetData = () => {},
    isEdit,
    setIsEdit = () => {},
    setModalVisible = () => {},
  } = props

  const {
    assets,
    setAssets,
    assetClassComponents,
    pageNumber,
    pageSize,
    handlePageChange,
    showToastrNotification,
    updateData,
    setComponentInitialValue,
  } = useContext(AssetContext)

  const [sort, setSort] = useState<any>(initialSort)

  const customHeaderCell = (props: any) => {
    return (
      <>
        <span onClick={props.onClick} className={'k-cell-inner'}>
          <span className={'k-link'}>
            <span
              className={'k-column-title fw-bolder'}
              style={{textAlign: 'center', marginBottom: 0}}
            >
              {props.title}
            </span>{' '}
            {props.children}
          </span>
        </span>
      </>
    )
  }

  /**HACK */
  let sortedComponents = assetClassComponents
  switch (sort[0]?.field) {
    case 'number':
      sortedComponents = orderBy(sortedComponents, sort)
      break
    case 'name':
      sortedComponents = orderBy(sortedComponents, sort)
      break
    case 'additionalValuerAdjustmentRate':
      sortedComponents = orderBy(sortedComponents, sort)
      break
    case 'secondaryAdjustmentFactor':
      sortedComponents = orderBy(sortedComponents, sort)
      break
    case 'usefulLife':
      sortedComponents = orderBy(sortedComponents, sort)
      break
    default:
      sortedComponents = sortedComponents
      break
  }

  const assetId = assetData?.id

  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null)
  const items = ['Update', 'Delete']
  const handleDropDownClick = (dataItem: any, action: string) => {
    if (action === 'Update') {
      setComponentInitialValue({...dataItem})
      setModalVisible(true)
      setIsEdit(!isEdit)
    } else if (action === 'Delete') {
      if (dataItem.id !== null) setSelectedDeleteId(dataItem.id)
    }
  }
  const handleCloseDeleteDialog = () => {
    setSelectedDeleteId(null)
  }

  const deleteItem = async (selectedDeleteId: any) => {
    try {
      // sortedComponents.length !== 1 &&
      showToastrNotification(
        'Asset component successfully deleted. Your changes will take effect after you click Save Changes.',
        'info'
      )

      setAssetData([...assetData].filter((component: any) => component?.id !== selectedDeleteId))

      const updatedAssets = assets.map((asset: any) => {
        if (asset.id === assetId) {
          const updatedComponents = asset.assetClassComponents.filter(
            (component: any) => component?.id !== selectedDeleteId
          )
          return {
            ...asset,
            assetClassComponents: updatedComponents,
          }
        }
        return asset
      })
      setAssets(updatedAssets)
      localStorage.setItem('assets', JSON.stringify(updatedAssets))
      setSelectedDeleteId(null)
    } catch (error) {
      showToastrNotification('Oops we encountered a problem. Please try again!', 'error')
      setSelectedDeleteId(null)
    }
    updateData()
  }

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <h3
          className='fw-bold'
          style={{
            color: '#000',
          }}
        >
          Asset Components
        </h3>
        <div className='d-flex align-items-center'>
          <div
            onClick={() => {
              setModalVisible(true)
              setComponentInitialValue(ASSET_COMPONENT_INITIAL)
            }}
            className='btn btn-sm btn-block fs-6 '
            style={{
              backgroundColor: '#a71304',
              color: '#fff',
              border: 'none',
            }}
          >
            <i className='bi bi-plus-lg' style={{color: '#fff'}}></i>Add Component
          </div>
        </div>
      </div>
      <>
        {/* DELETE MODAL */}
        {selectedDeleteId !== null && (
          <Dialog
            style={{backgroundColor: 'rgba(0,0,0,0.45)'}}
            title={'Delete Asset Component'}
            onClose={handleCloseDeleteDialog}
          >
            <div className='row mb-4'>
              <div className='col'>
                <p>Are you sure you want to delete this item?</p>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col  d-flex justify-content-end'>
                <Button
                  onClick={() => deleteItem(selectedDeleteId)}
                  buttonType='primary'
                  text={'Yes'}
                />
                <Button
                  buttonType='secondary'
                  text={'No'}
                  onClick={() => setSelectedDeleteId(null)}
                  style={{marginLeft: 10}}
                />
              </div>
            </div>
          </Dialog>
        )}
        <Grid
          data={
            sortedComponents?.slice(
              (pageNumber - 1) * pageSize,
              pageSize + (pageNumber - 1) * pageSize
            ).length === 0
              ? sortedComponents
              : sortedComponents?.slice(
                  (pageNumber - 1) * pageSize,
                  pageSize + (pageNumber - 1) * pageSize
                )
          }
          pageable={true}
          resizable={true}
          total={sortedComponents?.length}
          onPageChange={handlePageChange}
          take={pageSize}
          skip={(pageNumber - 1) * pageSize}
          sort={sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort)
          }}
        >
          <GridNoRecords>No components available</GridNoRecords>
          <Column field='number' title='Component Number' headerCell={customHeaderCell} />
          <Column field='name' title='Component Name' headerCell={customHeaderCell} />
          <Column
            field='additionalValuerAdjustmentRate'
            title='Additional Valuer Adjustment Rate'
            headerCell={customHeaderCell}
          />
          <Column
            field='secondaryAdjustmentFactor'
            title='Secondary Adjustment Factor'
            headerCell={customHeaderCell}
          />
          <Column field='usefulLife' title='Useful Life' headerCell={customHeaderCell} />

          <Column
            title=''
            cell={(props) => (
              <td className='align-middle text-center'>
                <ActionButton
                  svgIcon={gearIcon}
                  text='Actions'
                  items={items}
                  onItemClick={(event: any) => handleDropDownClick(props.dataItem, event.item)}
                />
              </td>
            )}
          />
        </Grid>
      </>
    </>
  )
}

export default AssetComponent
