import {useContext, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import Select from 'react-select'

import {ProjectAssetData} from '../../../models/project-assets'
import SectionTitleHeader from '../SectionTitleHeader'
import {
  getConstructionDifficulty,
  updateProjectAssetConstructionDifficulty,
} from '../../../api/asset-api'
import {ProjectContext} from '../../../context/ProjectContext'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IConstructionDifficultiesProps = {
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
}

type ConstructionDifficultyType = {
  fulcrumId: string
  fulcrumReference: string
  distanceFromRoad: string
  siteFactors: string
  fullText: string
  sizeSqm: number
  defaultUnitRate: number
  unAdjustedReplacementCost: number
  afterAdjustment: number
  allowance: number
  fulcrumReferenceChoices: string[]
}

const ConstructionDifficulties: React.FC<IConstructionDifficultiesProps> = ({
  selectedData,
  setSelectedData,
}) => {
  const [constructionDifficulty, setConstructionDifficulty] = useState<
    ConstructionDifficultyType[]
  >([])
  const {showToastrNotification} = useContext(ProjectContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: {errors},
    clearErrors,
    getValues,
    reset,
  } = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (selectedData) {
      reset({...selectedData})
    }
  }, [selectedData])

  const onSubmit = (values: ProjectAssetData) => {
    updateProjectAssetConstructionDifficulty(values, selectedData.id).then((response: any) => {
      if (response) {
        if (setSelectedData)
          setSelectedData({
            ...selectedData,
            distanceFromRoad: values.distanceFromRoad,
            constructionAndSiteFactors: values.constructionAndSiteFactors,
          })

        showToastrNotification('Asset Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
      }
      setIsEdit(false)
    })
  }

  const handleOnEdit = () => {
    setIsEdit(true)
  }

  const handleOnCancel = () => {
    setIsEdit(false)
    reset({...selectedData})
  }

  /**
   * DISTANCE FROM ROAD
   */
  const [distanceFromRoad, setDistanceFromRoad] = useState<{value: string; label: string}[]>([])

  /**
   * CONSTRUCTION DIFFICULTY FACTOR
   */
  const [constructionDifficultyFactor, setConstructionDifficultyFactor] = useState<
    {value: string; label: string}[]
  >([])

  const getDifficultyFactor = () => {
    const difficultyFactors: {value: string; label: string}[] = constructionDifficulty
      .filter(
        (difficulty: ConstructionDifficultyType) =>
          difficulty?.distanceFromRoad === getValues('distanceFromRoad')
      )
      .map((difficulty: ConstructionDifficultyType) => ({
        value: difficulty.siteFactors,
        label: difficulty.siteFactors,
      }))

    setConstructionDifficultyFactor(difficultyFactors)
  }

  useEffect(() => {
    getDifficultyFactor()
  }, [getValues('distanceFromRoad')])

  const fetchDistanceFromRoad = async () => {
    const response = await getConstructionDifficulty()
    setConstructionDifficulty(response.data)

    const distanceSet = new Set()
    response.data.forEach(
      (distance: {
        fulcrumId: string
        fulcrumReference: string
        distanceFromRoad: string
        siteFactor: number | null
        fullText: string
        sizeSqm: number
        defaultUnitRate: number
        unAdjustedReplacementCost: number
        afterAdjustment: number
        allowance: number
        fulcrumReferenceChoices: string[]
      }) => {
        distanceSet.add(distance.distanceFromRoad)
      }
    )

    const distanceOptions = Array.from(distanceSet).map((distance) => ({
      value: distance,
      label: distance,
    })) as {value: string; label: string}[]

    setDistanceFromRoad(distanceOptions)
  }

  useEffect(() => {
    fetchDistanceFromRoad()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionTitleHeader
        title='Construction Difficulty'
        isEdit={isEdit}
        onEdit={handleOnEdit}
        onCancel={() => {
          handleOnCancel()
        }}
        permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
      />
      <div id='constructionDifficulty'>
        <div className='col'>
          <div className='form-outline mb-4'>
            <label className='fw-bolder' htmlFor='distance'>
              Distance From Road
            </label>
            {isEdit ? (
              <Controller
                control={control}
                name='distanceFromRoad'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Distance From Road'
                      id='distance'
                      isMulti={false}
                      options={distanceFromRoad}
                      value={{
                        label: value,
                        value: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('distanceFromRoad')
                        // setValue('constructionAndSiteFactors', '')
                      }}
                      className={`controllerSelect${
                        errors.distanceFromRoad ? ' border-danger' : ''
                      }`}
                    />
                    {errors.distanceFromRoad && (
                      <small className='text-danger'>{errors.distanceFromRoad.message}</small>
                    )}
                  </div>
                )}
              />
            ) : (
              <p>{getValues('distanceFromRoad')}</p>
            )}
          </div>
          <div className='form-outline'>
            <label className='fw-bolder' htmlFor='difficulty'>
              Construction Difficulty Factor
            </label>
            {isEdit ? (
              <Controller
                control={control}
                name='constructionAndSiteFactors'
                render={({field: {onChange, value}}) => (
                  <div>
                    <Select
                      placeholder='Select Construction Difficulty Factor'
                      id='constructionAndSiteFactors'
                      isMulti={false}
                      options={constructionDifficultyFactor}
                      value={{
                        label: value,
                        value: value,
                      }}
                      onChange={(e) => {
                        onChange(e?.value)
                        clearErrors('constructionAndSiteFactors')
                      }}
                      className={`controllerSelect${
                        errors.constructionAndSiteFactors ? ' border-danger' : ''
                      }`}
                    />
                    {errors.constructionAndSiteFactors && (
                      <small className='text-danger'>
                        {errors.constructionAndSiteFactors.message}
                      </small>
                    )}
                  </div>
                )}
              />
            ) : (
              <p>{getValues('constructionAndSiteFactors')}</p>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default ConstructionDifficulties
