import {
  Control,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  UseFormClearErrors,
  UseFormHandleSubmit,
  useForm,
} from 'react-hook-form'
import {ClientSpecificCustomFields} from './ClientSpecificCustomFields'
import {useContext, useEffect, useState} from 'react'
import {ProjectAssetData, ProjectData} from '../../../models/project-assets'
import {IProjectData} from '../../../models/project-model'
import {ProjectContext} from '../../../context/ProjectContext'
import SectionTitleHeader from '../SectionTitleHeader'
import {updateProjectAssetCustomFields} from '../../../api/asset-api'
import {transformSaveProjectAssetCustomFields} from '../../../transformers/project-transformer'
import {PAGE_PERMISSION} from '../../../../roles/models/role-model'

type IClientSpecificsProps = {
  control: Control<ProjectAssetData>
  isValid: boolean
  setValue: UseFormSetValue<any>
  errors: FieldErrors<any>
  register: UseFormRegister<any>
  clearErrors: UseFormClearErrors<any>
  handleSubmit: UseFormHandleSubmit<any>
  selectedData: ProjectAssetData
  setSelectedData: (value: ProjectAssetData) => void
  projectId: string
  projectData: IProjectData | ProjectData
}

function ClientSpecifics(props: IClientSpecificsProps) {
  const {projectData, selectedData} = props
  const {tableData} = useContext(ProjectContext)
  const data = tableData.find((data: any) => data?.id === projectData?.id)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {showToastrNotification} = useContext(ProjectContext)

  const handleOnEdit = () => {
    setIsEdit(true)
  }

  const handleOnCancel = () => {
    reset({...selectedData})
    setIsEdit(false)
  }

  const {control, handleSubmit, reset, getValues} = useForm({
    defaultValues: selectedData,
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (values: ProjectAssetData) => {
    const payload = {
      ...values,
      projectAssetCustomFields: transformSaveProjectAssetCustomFields(
        values.projectAssetCustomFields
      ),
    }
    updateProjectAssetCustomFields(payload).then((response: any) => {
      if (response && response.data) {
        showToastrNotification('Asset Saved succesfully', 'success')
      } else {
        showToastrNotification('Error Saving Asset. Please try again.', 'error')
      }
      setIsEdit(false)
    })
  }

  useEffect(() => {
    reset({...selectedData})
  }, [selectedData])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitleHeader
          title='Client Specifics'
          isEdit={isEdit}
          onEdit={handleOnEdit}
          onCancel={handleOnCancel}
          permission={PAGE_PERMISSION.ASSETS_PAGE_UPDATE}
        />
        <div id='clientSpecifics'>
          <div className='col'>
            <div className='row mt-5'>
              <div className='col'>
                {data?.projectCustomFields?.length === 0 ? (
                  <p>No Custom Fields defined</p>
                ) : (
                  <ClientSpecificCustomFields
                    isEdit={isEdit}
                    projectId={selectedData.projectId}
                    control={control}
                    getValues={getValues}
                    // projectAssetCustomFields={projectAssetCustomFields}
                    // replaceProjectAssetCustomFields={replaceProjectAssetCustomFields}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ClientSpecifics
